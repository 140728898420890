import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';
import { ProtocolModel } from '../models/protocol.model';
import { ProtocolService } from '../services/protocol.service';
import { protocolCreate } from '../store/protocol.actions';
import { ProtocolState } from '../store/protocol.reducers';

@Component({
  selector: 'app-protocol-add',
  templateUrl: './protocol-add.component.html',
  styleUrls: ['./protocol-add.component.scss']
})
export class ProtocolAddComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  model = { } as ProtocolModel;

  constructor(private service: ProtocolService, private store: Store<ProtocolState>, private router: Router) { }

  ngOnInit(): void {
    this.fields = this.service.buildForm(this.model, true);
  }

  save(): void {
    this.form.markAsPristine();
    this.store.dispatch(protocolCreate({ data: { protocol: { ...this.model } } }));
  }

  cancel(): void {
    this.router.navigate(['protocol']);
  }

}
