/* eslint-disable arrow-body-style */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CategoryModel } from '../models/category.model';
import { OptionType } from '../models/option-type.enum';
import { OptionModel } from '../models/option.model';
import { SubCategoryModel } from '../models/sub-category.model';
import { optionsCreate, optionsUpdate } from '../store/option.actions';
import { OptionState } from '../store/option.reducers';
import { optionSelector } from '../store/option.selectors';
import { AddEditBaseOptionComponent } from './add-edit-base-option/add-edit-base-option.component';

@Component({
  selector: 'app-option-management',
  templateUrl: './option-management.component.html',
  styleUrls: ['./option-management.component.scss']
})
export class OptionManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  optionTypeArray: string[] = Object.values(OptionType).sort();
  data$!: Observable<OptionModel[] | OptionState | undefined>;
  headElements = ['name', 'isActive', 'actions'];
  specialFindingElements = ['name', 'category', 'isActive', 'actions'];
  selected$ = new BehaviorSubject<number>(0);
  dataSource = new MatTableDataSource<OptionModel | CategoryModel | SubCategoryModel>();
  subs$: Subscription[] = [];
  resultsLength = 0;
  searchKey!: string;
  constructor(private store: Store<OptionState>, private dialog: MatDialog) { }

  ngOnInit(): void {

    // Bug fix for capitalization in sorting
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
    this.loadData(0);
  }

  loadData(tab: number): void {
    this.selected$.next(tab);

    // Skipping this if event is on Special Findings Tab
    if (tab <= 9) {
      const type = this.optionTypeArray[tab] as OptionType;
      this.data$ = this.store.pipe(select(optionSelector.optionsByType(type))).pipe(tap(options => {
        this.dataSource.data = options;
        this.dataSource.filterPredicate = (data, filter) => {
          return this.headElements.some(ele => {
            return ele !== 'actions' && ele !== 'isActive' && data[ele].toLowerCase().indexOf(filter) !== -1;
          });
        };
        this.resultsLength = options.length;
      }));
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  applyFilter() {
    if (this.searchKey != null) {
      this.dataSource.filter = this.searchKey.trim().toLowerCase();
    }
  }

  openCreateModal(type: string): void {
    const dialogRef = this.dialog.open(AddEditBaseOptionComponent, {
      width: '500px',
      data: { id: 0, optionType: type }
    });

    this.subs$.push(
      dialogRef.afterClosed().subscribe((model: OptionModel) => {
        if (model != null) {
          this.store.dispatch(optionsCreate({ data: { option: { ...model } } }));
        }
      })
    );
  }

  openEditModal(option: OptionModel): void {
    const dialogRef = this.dialog.open(AddEditBaseOptionComponent, {
      width: '500px',
      data: { ...option }
    });

    this.subs$.push(
      dialogRef.afterClosed().subscribe((model: OptionModel) => {
        if (model != null) {
          this.store.dispatch(optionsUpdate({ data: { option: { ...model } } }));
        }
      })
    );
  }

  tabChange(event: number): void {
    this.paginator.pageIndex = 0;
    this.loadData(event);
  }

  sortData(sort: Sort): void {
    this.sort.direction = sort.direction;
    this.sort.active = sort.active;
    this.loadData(this.selected$.value);
  }

  pageData(page: PageEvent): void {
    this.paginator.pageIndex = page.pageIndex;
    this.paginator.pageSize = page.pageSize;
    this.loadData(this.selected$.value);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

}
