/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AppState } from '@core/app-state/reducers';
import { OptionType } from '@features/admin/option-management/models/option-type.enum';
import { OptionModel } from '@features/admin/option-management/models/option.model';
import { optionSelector } from '@features/admin/option-management/store/option.selectors';
import { ProtocolModel } from '@features/protocols/models/protocol.model';
import { protocolById } from '@features/protocols/store/protocol.actions';
import { ReportService } from '@features/reports/service/report.service';
import { StatusReportDataSource } from '@features/reports/status-report.dataSource';
import { select, Store } from '@ngrx/store';
import { ApiService } from '@shared/services/api/api.service';
import { NgxNotifyService } from '@shared/services/ngx-notify/ngx-notify.service';
import { merge, Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-statusreport',
  templateUrl: './statusreport.component.html',
  styleUrls: ['./statusreport.component.scss']
})
export class StatusReportComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  schools!: OptionModel[];
  filteredSchools$!: Observable<OptionModel[]>;
  institutions!: OptionModel[];
  filteredInstitutions$!: Observable<OptionModel[]>;
  departments!: OptionModel[];
  filteredDepartments$!: Observable<OptionModel[]>;
  status!: OptionModel[];
  filteredStatus$!: Observable<OptionModel[]>;
  investigators!: OptionModel[];
  filteredInvestigators$!: Observable<OptionModel[]>;
  typeOfReview!: OptionModel[];
  filteredTypeOfReview$!: Observable<OptionModel[]>;
  ntrIrbCoordinators!: OptionModel[];
  filteredNtrIrbCoordinators$!: Observable<OptionModel[]>;
  typeOfResearch!: OptionModel[];
  filteredTypeOfResearch$!: Observable<OptionModel[]>;

  dateMultiSelect: any[] = [{ value: 'approvalThrough', label: 'Approval Through Date' }, { value: 'completeDate', label: 'Complete Date' },
  { value: 'continuingReviewDate', label: 'Continuing Review Date' }, { value: 'initialApprovalDate', label: 'Initial Approval Date' },
  { value: 'initialReview', label: 'Initial Review Date' }, { value: 'mostRecentReview', label: 'Most Recent Review Date' },
  { value: 'sendNextReviewDate', label: 'Send Next Review Date' }, { value: 'submissionDate', label: 'Submission Date' }];

  downloadingFullReport = false;
  downloadingStatusReport = false;
  dataSource!: StatusReportDataSource;
  displayedColumns = ['irbNumber', 'institutionId', 'schoolId', 'departmentId', 'investigatorId', 'ntrIrbCoordinatorId', 'statusId', 'typeOfReviewId', 'approvalThrough', 'sendNextReviewDate'];
  subs$: Subscription[] = [];
  constructor(private store: Store<AppState>,
    private fb: FormBuilder,
    public api: ApiService,
    public reportService: ReportService,
    private router: Router,
    private notify: NgxNotifyService) { }

  ngOnInit(): void {
    this.setFormOptions();
    this.dataSource = new StatusReportDataSource(this.api);
    this.loadPage();
   
  }

  displayFn(option: OptionModel): string {
    return option && option.name ? option.name : '';
  }

  checkValue(event: any) {
    if (event?.value !== null) {
      this.reportService.form.controls.dates.enable();
    } else {
      this.reportService.form.controls.dates.disable();
    }
  }

  resetFilter(): void {
    this.reportService.form.reset();
    this.reportService.pageIndex = this.paginator.pageIndex = 0;//reset index to 0 
    this.reportService.form.controls.dates.disable();
    this.setFormOptions();
    this.insert(this.reportService.form.value);
  }

  loadPage(): void {
    // tslint:disable-next-line: max-line-length
    this.paginator.pageIndex = this.reportService.pageIndex;
    this.paginator.pageSize = this.reportService.pageSize;
    this.dataSource.loadData(this.reportService.filter, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  sortData(): void {
    this.reportService.pageIndex = this.paginator.pageIndex = 0;//reset index to 0 
    this.loadPage();
  }

  onSubmit(): void {
    this.insert(this.reportService.form.value);
  }

  downloadExcel(fullReport: boolean) {
    if (fullReport === true) {
      this.downloadingFullReport = true;
      this.reportService.filter = this.reportService.filter + `&forStatusReport=false&fullReport=true`;
    } else {
      this.downloadingStatusReport = true;
      this.reportService.filter = this.reportService.filter + `&forStatusReport=true&fullReport=false`;
    }
    this.subs$.push(
      this.api.downloadExcelFile('report?' + this.reportService.filter).subscribe(blob => {
        if (blob != null) {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          if (fullReport) {
            a.download = 'FullReport';
          } else {
            a.download = 'StatusReport';
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.downloadingFullReport = false;
          this.downloadingStatusReport = false;
          // resetting filter back to false so that other filters still work properly
          this.reportService.filter = this.reportService.filter.replace('&forStatusReport=false&fullReport=true', '');
          this.reportService.filter = this.reportService.filter.replace('&forStatusReport=true&fullReport=false', '');
        }
      },
        (error: any) => {
          this.downloadingFullReport = false;
          this.downloadingStatusReport = false;
          this.reportService.filter = this.reportService.filter.replace('&forStatusReport=false&fullReport=true', '');
          this.reportService.filter = this.reportService.filter.replace('&forStatusReport=true&fullReport=false', '');
        })
    );
  }

  view(protocolId: number): void {
    this.store.dispatch(protocolById({ id: protocolId }));
    this.router.navigate(['/protocol', protocolId]);
  }

  ngOnDestroy(): void {
    this.subs$.map(x => x != null ? x.unsubscribe() : null);
  }

  private setFormOptions(): void {
    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.institution))).subscribe((options: OptionModel[]) => {
      this.institutions = options;
      this.filteredInstitutions$ = this.reportService.form.controls.institutionId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.institutions) : this.institutions.slice()));
    }));

    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.department))).subscribe((options: OptionModel[]) => {
      this.departments = options;
      this.filteredDepartments$ = this.reportService.form.controls.departmentId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.departments) : this.departments.slice()));
    }));

    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.investigator))).subscribe((investigators: OptionModel[]) => {
      this.investigators = investigators;
      this.filteredInvestigators$ = this.reportService.form.controls.investigatorId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.investigators) : this.investigators.slice()));
    }));

    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.nTRIRBCoordinator))).subscribe((options: OptionModel[]) => {
      this.ntrIrbCoordinators = options;
      this.filteredNtrIrbCoordinators$ = this.reportService.form.controls.ntrIrbCoordinatorId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.ntrIrbCoordinators) : this.ntrIrbCoordinators.slice()));
    }));

    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.school))).subscribe((options: OptionModel[]) => {
      this.schools = options;
      this.filteredSchools$ = this.reportService.form.controls.schoolId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.schools) : this.schools.slice()));
    }));
    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.status))).subscribe((options: OptionModel[]) => {
      this.status = options;
      this.filteredStatus$ = this.reportService.form.controls.statusId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.status) : this.status.slice()));
    }));
    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.typeOfReview))).subscribe((options: OptionModel[]) => {
      this.typeOfReview = options;
      this.filteredTypeOfReview$ = this.reportService.form.controls.typeOfReviewId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.typeOfReview) : this.typeOfReview.slice()));
    }));
    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.typeOfResearch))).subscribe((options: OptionModel[]) => {
      this.typeOfResearch = options;
      this.filteredTypeOfResearch$ = this.reportService.form.controls.typeOfResearchId.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.typeOfResearch) : this.typeOfResearch.slice()));
    }));
  }
  private _filter(name: string, optionList: OptionModel[]): any {
    const filterValue = name.trim().toLowerCase();
    return optionList.filter(option => option.name.trim().toLowerCase().includes(filterValue));
  }

  private insert(data: any): void {
    if (data != null) {
      this.reportService.filter = '';
      if (data.schoolId != null && data.schoolId !== undefined && data.schoolId !== 0 && data.schoolId !== '') {
        if (isNaN(parseInt(data.schoolId.id))) {
          this.notify.error(`School has invalid value: ${data.schoolId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `schoolId=${data.schoolId.id}&`;
        }
      }
      if (data.institutionId != null && data.institutionId !== undefined && data.institutionId !== 0 && data.institutionId !== '') {
        if (isNaN(parseInt(data.institutionId.id))) {
          this.notify.error(`Institution has invalid value: ${data.institutionId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `institutionId=${data.institutionId.id}&`;
        }
      }
      if (data.departmentId != null && data.departmentId !== undefined && data.departmentId !== 0 && data.departmentId !== '') {
        if (isNaN(parseInt(data.departmentId.id))) {
          this.notify.error(`Department has invalid value: ${data.departmentId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `departmentId=${data.departmentId.id}&`;
        }
      }
      if (data.investigatorId != null && data.investigatorId !== undefined && data.investigatorId !== 0 && data.investigatorId !== '') {
        if (isNaN(parseInt(data.investigatorId.id))) {
          this.notify.error(`Investigator has invalid value: ${data.investigatorId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `investigatorId=${data.investigatorId.id}&`;
        }
      }
      if (data.statusId != null && data.statusId !== undefined && data.statusId !== 0 && data.statusId !== '') {
        if (isNaN(parseInt(data.statusId.id))) {
          this.notify.error(`Status has invalid value: ${data.statusId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `statusId=${data.statusId.id}&`;
        }
      }
      if (data.typeOfReviewId != null && data.typeOfReviewId !== undefined && data.typeOfReviewId !== 0 && data.typeOfReviewId !== '') {
        if (isNaN(parseInt(data.typeOfReviewId.id))) {
          this.notify.error(`Type Of Review has invalid value: ${data.typeOfReviewId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `typeOfReviewId=${data.typeOfReviewId.id}&`;
        }
      }
      if (data.irbNumber != null) {
        this.reportService.filter = this.reportService.filter + `irbNumber=${data.irbNumber}&`;
      }
      if (data.startDate != null) {
        this.reportService.filter = this.reportService.filter + `startDate=${data.startDate.toLocaleString()}&`;
      }
      if (data.endDate != null) {
        this.reportService.filter = this.reportService.filter + `endDate=${data.endDate.toLocaleString()}&`;
      }
      if (data.ntrIrbCoordinatorId != null && data.ntrIrbCoordinatorId !== undefined && data.ntrIrbCoordinatorId !== 0 && data.ntrIrbCoordinatorId !== '') {
        if (isNaN(parseInt(data.ntrIrbCoordinatorId.id))) {
          this.notify.error(`NTRIRB Coordinator has invalid value: ${data.ntrIrbCoordinatorId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `ntrIrbCoordinatorId=${data.ntrIrbCoordinatorId.id}&`;
        }
      }
      if (data.irbNetNumber != null) {
        this.reportService.filter = this.reportService.filter + `irbNetNumber=${data.irbNetNumber}&`;
      }
      if (data.keyword != null) {
        this.reportService.filter = this.reportService.filter + `keyword=${data.keyword}&`;
      }
      if (data != null && data.dates != null) {

        if (data.dates.length > 0) {
          for (const dateVal of data.dates) {
            this.reportService.filter = this.reportService.filter + `${dateVal}=true&`;
          }
        }
      }
      if (data.typeOfResearchId != null && data.typeOfResearchId !== undefined && data.typeOfResearchId !== 0 && data.typeOfResearchId !== '') {
        if (isNaN(parseInt(data.typeOfResearchId.id))) {
          this.notify.error(`Type Of Research has invalid value: ${data.typeOfResearchId}`);
        } else {
          this.reportService.filter = this.reportService.filter + `typeOfResearchId=${data.typeOfResearchId.id}&`;
        }
      }
    }
    this.reportService.pageIndex = 0; // If the user applies filter, reset back to the first page.
    this.reportService.pageIndex = this.paginator.pageIndex;
    this.reportService.pageSize = this.paginator.pageSize;  
    this.loadPage();
  }

  onPageChange(event: any) {
    this.reportService.pageSize = event.pageSize;
    this.reportService.pageIndex = event.pageIndex;
    this.loadPage();
  }
}
