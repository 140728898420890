import { Action, createReducer, on } from '@ngrx/store';
import { ProtocolModel } from '../models/protocol.model';
import * as protocolActions from './protocol.actions';

export const PROTOCOL_FEATURE_KEY = 'protocols';

export interface ProtocolState {
    protocols: ProtocolModel[];
    selectedProtocol: ProtocolModel;
    count: number;
}

export const initialProtocolState: ProtocolState = {
    protocols: [],
    selectedProtocol: { } as ProtocolModel,
    count: 0
};

export const protocolReducer = createReducer(
    initialProtocolState,
    on(protocolActions.protocolSearchLoaded, (state, { protocols, count }) => ({ ...state, protocols, count })),
    on(protocolActions.protocolByIdLoaded, (state, action) => ({ ...state, selectedProtocol: action.data.protocol })),
    on(protocolActions.protocolCreateLoaded, (state, action) => ({ ...state, selectedProtocol: action.data.protocol })),
    on(protocolActions.protocolUpdateLoaded, (state, action) => ({ ...state, selectedProtocol: action.data.protocol }))
);

export const reducer = (state: ProtocolState, action: Action) => protocolReducer(state, action);
