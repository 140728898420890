<mat-card style="min-height: 700px">
  <app-card-header title="Protocol Management" subtitle="IRB Protocols"></app-card-header>
  <mat-card-content class="p-3">
    <div class="search-div">
      <form [formGroup]="protocolService.protocolFilterForm" novalidate (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Search by IRB Number</mat-label>
              <input formControlName="irbNumber" name="irbNumber" placeholder="IRB Number" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Search by IRBNet Number</mat-label>
              <input formControlName="irbNetNumber" name="irbNetNumber" placeholder="IRBNet Number" matInput>
            </mat-form-field>

          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Search by Study Title</mat-label>
              <input formControlName="studyTitle" name="studyTitle" placeholder="studyTitle" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Search by Investigator</mat-label>
              <input type="text" matInput formControlName="investigatorId" [matAutocomplete]="investigatorAutoComplete">
              <mat-autocomplete #investigatorAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let investigator of filteredInvestigators$ | async" [value]="investigator">
                  {{investigator.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Search by NTRIBCoordinator</mat-label>
              <input type="text" matInput formControlName="ntrIrbCoordinatorId"
                [matAutocomplete]="ntrIrbCoordinatorAutoComplete">
              <mat-autocomplete #ntrIrbCoordinatorAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let ntrIrbCoordinator of filteredNtrIrbCoordinators$ | async"
                  [value]="ntrIrbCoordinator">
                  {{ntrIrbCoordinator.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Search by Status</mat-label>
              <input type="text" matInput formControlName="statusId" [matAutocomplete]="statusAutoComplete">
              <mat-autocomplete #statusAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let status of filteredStatus$ | async" [value]="status">
                  {{status.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label class="text-decoration">Approval Through Date</mat-label>
              <input matInput [matDatepicker]="approvalThroughpicker" placeholder="MM/DD/YYYY"
                (change)="onDateChange($event,'approvalThrough')" formControlName="approvalThrough" />
              <mat-datepicker-toggle matSuffix [for]="approvalThroughpicker"></mat-datepicker-toggle>
              <mat-datepicker #approvalThroughpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label class="text-decoration">Send Next Review</mat-label>
              <input matInput [matDatepicker]="sendNextReviewpicker" placeholder="MM/DD/YYYY"
                (change)="onDateChange($event,'sendNextReview')" formControlName="sendNextReview" />
              <mat-datepicker-toggle matSuffix [for]="sendNextReviewpicker"></mat-datepicker-toggle>
              <mat-datepicker #sendNextReviewpicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="mr-3" mat-stroked-button color="warn" (click)="resetFilter()">Clear Search</button>
          <button type="submit" class="mr-3" mat-stroked-button color="primary">Submit</button>
        </div>
      </form>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <div *ngIf="isAdmin$ | async" fxLayout="column">
        <button mat-stroked-button color="accent" routerLink="/protocol/add"><mat-icon>add</mat-icon>Add
          Protocol</button>
      </div>
    </div>

    <div class="table-container">
      <div *ngIf="loading$ | async" style="position: absolute; right: 50%; top: 35%;">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div>
        <mat-paginator #topPaginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 100]" [pageIndex]="protocolService.pageIndex" (page)="onPageChange($event)"></mat-paginator>
        <mat-table *ngIf="data$ | async as data" [dataSource]="data" matSort (matSortChange)="sortData()"
          class="mat-elevation-z8 mt-3">
          <ng-container matColumnDef="irbNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> IRB Number </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element?.irbNumber }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="investigator">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Investigator </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element?.investigator?.name || '' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="studyTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Study Title </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element?.studyTitle || '' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element?.status?.name || '' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="approvalThrough">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Approval Through </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ (element?.approvalThrough | date) || '' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container>
                <button class="mr-2" mat-stroked-button color="accent" (click)="view(element.id)">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="headElements"></mat-header-row>
          <mat-row *matRowDef="let row; columns: headElements;"></mat-row>
        </mat-table>
        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 100]" [pageIndex]="protocolService.pageIndex" (page)="onPageChange($event)"></mat-paginator>
      </div>

    </div>
  </mat-card-content>
</mat-card>