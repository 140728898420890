<mat-toolbar class="nav-bar">
  <mat-toolbar-row>
    <div class="nav-item">
      <span class="d-flex align-items-center"><img class="nav-logo" src="https://hsccdn.blob.core.windows.net/devcdn/logo-stars-reversed.png" alt="logo" /> IRB</span>
    </div>
    <span class="nav-logo"></span>
    <mat-nav-list *ngIf="data$ | async as data">
      <a mat-button fxShow fxHide.lt-md *ngFor="let item of navigation" routerLink="/">Protocols</a>
      <a mat-button fxShow fxHide.lt-md *ngFor="let item of navigation" routerLink="/report">Reports</a>
      <a *ngIf="data.isAdmin || data.isWarden" mat-button fxShow fxHide.lt-md routerLink="admin/users">Users</a>
      <a *ngIf="data.isAdmin || data.isWarden" mat-button fxShow fxHide.lt-md routerLink="admin/options">Options</a>
    </mat-nav-list>
    <span style="flex: 1 1 auto"></span>
    <ng-container *ngIf="data$ | async as data; else noAuth">
      <button mat-button [matMenuTriggerFor]="menu"><mat-icon>person</mat-icon> {{ data.user?.given_name }} {{ data.user?.family_name }}</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/home">Protocols</button>
        <span *ngIf="data.isAdmin || data.isWarden">
          <button mat-menu-item [routerLink]="'/admin/users'">Users</button>
          <button mat-menu-item routerLink="/admin/options">Options</button>
        </span>
        <button mat-menu-item routerLink="/report">Reports</button>
        <button mat-menu-item (click)="logout()">Logout</button>
        <span *ngIf="data.isWarden">
          <button mat-menu-item (click)="copyToClipboard()">Get Token</button>
        </span>
      </mat-menu>
    </ng-container>
    <ng-template #noAuth>
      <button mat-button (click)="login()">Login</button>
    </ng-template>
  </mat-toolbar-row>
</mat-toolbar>
