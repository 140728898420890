import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import {AddEditFindingsCategoryComponent} from '@features/admin/option-management/components/add-edit-findings-category/add-edit-findings-category.component';
import { OptionStoreModule } from '@features/admin/option-management/store/option-store.module';
import { UserManagementComponent } from '@features/admin/user-management/components/user-management.component';
import { UserStoreModule } from '@features/admin/user-management/store/user-store.module';
import { HomeComponent } from '@features/home/home.component';
import { OptionTypePipe } from '@features/admin/option-management/pipes/option-type.pipe';
import { ProtocolStoreModule } from '@features/protocols/store/protocol-store.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { RichTextEditorComponent } from '@shared/components/rich-text-editor/rich-text-editor.component';
import { SharedModule } from '@shared/shared.module';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from 'src/app/app.component';

import { AppRoutingModule } from './app-routing.module';
import {AddEditBaseOptionComponent} from './features/admin/option-management/components/add-edit-base-option/add-edit-base-option.component';
import {AddEditFindingsComponent} from './features/admin/option-management/components/add-edit-findings/add-edit-findings.component';
import {FindingsCategoryComponent} from './features/admin/option-management/components/findings-category/findings-category.component';
import { FindingsComponent } from './features/admin/option-management/components/findings/findings.component';
import { OptionManagementComponent } from './features/admin/option-management/components/option-management.component';
import { UserAddComponent } from './features/admin/user-management/components/user-add/user-add.component';
import { UserEditComponent } from './features/admin/user-management/components/user-edit/user-edit.component';
import { ProtocolAddComponent } from './features/protocols/protocol-add/protocol-add.component';
import { ProtocolEditComponent } from './features/protocols/protocol-edit/protocol-edit.component';
import { ProtocolHistoryModalComponent} from './features/protocols/protocol-history/protocol-history-modal/protocol-history-modal.component';
import { ProtocolHistoryComponent } from './features/protocols/protocol-history/protocol-history.component';
import { ProtocolsListComponent } from './features/protocols/protocols-list/protocols-list.component';
import { StatusReportComponent } from '@features/reports/report/statusreport/statusreport.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProtocolsListComponent,
    UserManagementComponent,
    UserEditComponent,
    UserAddComponent,
    OptionManagementComponent,
    AddEditBaseOptionComponent,
    ProtocolAddComponent,
    ProtocolEditComponent,
    RichTextEditorComponent,
    AddEditFindingsComponent,
    AddEditFindingsCategoryComponent,
    FindingsComponent,
    FindingsCategoryComponent,
    ProtocolHistoryComponent,
    ProtocolHistoryModalComponent,
    OptionTypePipe,
    StatusReportComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    UserStoreModule,
    OptionStoreModule,
    ProtocolStoreModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      types: [{
        name: 'rich-text',
        component: RichTextEditorComponent
      }]
    }),    
    FormlyMaterialModule,
    QuillModule.forRoot(),
    FormlyMaterialModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule
  ],
  providers: [OptionTypePipe],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
