import { OptionTypePipe } from '@features/admin/option-management/pipes/option-type.pipe';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { CategoryModel } from '../models/category.model';

import { OptionType } from '../models/option-type.enum';
import { OptionModel } from '../models/option.model';
import { SubCategoryModel } from '../models/sub-category.model';
import { OptionState } from '../store/option.reducers';
import { optionSelector } from '../store/option.selectors';

@Injectable({ providedIn: 'root' })
export class OptionTypeService {
  model = {} as OptionModel | SubCategoryModel | CategoryModel;

  constructor(private store: Store<OptionState>, private optionType: OptionTypePipe) {
    // Loads options into store
    this.initOptionState();
  }

  buildOptionForm(type: OptionType, model: OptionModel): FormlyFieldConfig[] {
    this.model = model;

    return [
      {
        // eslint-disable-next-line max-len
        template: `<div class="form-section mat-elevation-z4">${model.name != null ? 'Edit' : 'New'} ${this.optionType.transform(type)} Option</div>`
      },
      {
        template: `<p>The title set below will appear in the dropdown menu.</p>`
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'checkbox',
            key: 'isActive',
            defaultValue: this.model.isActive ?? true,
            templateOptions: {
              label: 'Active'
            }
          }
        ]
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            defaultValue: this.model.name,
            templateOptions: {
              appearance: 'fill',
              label: 'Title',
              required: true,
              maxLength: 600
            }
          }
        ]
      }
    ];
  }

  buildSubCategoryForm(model: SubCategoryModel): FormlyFieldConfig[] {
    this.model = model;

    return [
      {
        template: `<div class="form-section mat-elevation-z4">${model.name != null ? 'Edit' : 'New'} Special Finding</div>`
      },
      {
        template: `<p>Setting Status to active or inactive will change of this sub-category as well as its parent category status.</p>`
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'checkbox',
            key: 'isActive',
            defaultValue: this.model.isActive ?? true,
            templateOptions: {
              label: 'Active'
            }
          }
        ]
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'select',
            key: 'categoryId',
            templateOptions: {
              appearance: 'fill',
              label: 'Special Findings Category',
              valueProp: 'id',
              labelProp: 'name',
              options: this.store.pipe(select(optionSelector.categories)),
            }
          },
        ]
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            defaultValue: this.model.name,
            templateOptions: {
              appearance: 'fill',
              label: 'Title',
              required: true,
              maxLength: 500
            }
          }
        ]
      }
    ];
  }

  buildCategoryForm(model: CategoryModel): FormlyFieldConfig[] {
    this.model = model;

    return [
      {
        template: `<div class="form-section mat-elevation-z4">${model.name != null ? 'Edit' : 'New'} Special Finding Category</div>`
      },
      {
        template: `<p>Setting Status to active or inactive will change of this category as well as any children sub-categories status</p>`
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'checkbox',
            key: 'isActive',
            defaultValue: this.model.isActive ?? true,
            templateOptions: {
              label: 'Active'
            }
          }
        ]
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12',
            type: 'input',
            key: 'name',
            defaultValue: this.model.name,
            templateOptions: {
              appearance: 'fill',
              label: 'Title',
              required: true,
              maxLength: 250
            }
          }
        ]
      }
    ];
  }

  private initOptionState(): Observable<OptionState> {
    return this.store.pipe(select(optionSelector.allOptions));
  }
}
