<mat-card style="min-height: 700px">
    <ng-container *ngIf="data$ | async as data">
        <app-card-header title="Edit Protocol" subtitle="{{data.irbNumber}}: {{data.studyTitle}}" [ngClass]="routeAnimationsElements">
        </app-card-header>
        <mat-card-content class="p-3" [ngClass]="routeAnimationsElements">
            <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-stroked-button color="warn" (click)="cancel()">Back</button>
            <button *ngIf="isAdmin$ | async" mat-stroked-button color="accent" [disabled]="form.invalid || form.pristine" (click)="save()">Update</button>
        </mat-card-actions>
    </ng-container>

    <ng-container *ngIf="!(data$ | async)">
        <div fxLayout="row" fxLayoutAlign="center" class="mt-5">
            <h1>The selected protocol could not be found</h1>
        </div>
    </ng-container>
</mat-card>

<mat-card class="mt-3">
    <ng-container *ngIf="data$ | async as data">
        <app-card-header title="Protocol History" subtitle="Change History" [ngClass]="routeAnimationsElements"></app-card-header>
        <mat-card-content class="p-3" [ngClass]="routeAnimationsElements">
            <app-protocol-history [history$]="data$"></app-protocol-history>
        </mat-card-content>
    </ng-container>
</mat-card>