import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { authSelector } from '@core/services/auth/state/authentication.selectors';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ProtocolModel } from '../models/protocol.model';
import { ProtocolService } from '../services/protocol.service';
import { protocolById, protocolUpdate } from '../store/protocol.actions';
import { ProtocolState } from '../store/protocol.reducers';
import { protocolSelector } from './../store/protocol.selectors';

@Component({
  selector: 'app-protocol-edit',
  templateUrl: './protocol-edit.component.html',
  styleUrls: ['./protocol-edit.component.scss']
})
export class ProtocolEditComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  data$!: Observable<ProtocolModel>;
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  model = {} as ProtocolModel;
  isAdmin$ = this.store.pipe(select(authSelector.isAdmin));

  constructor(
    private store: Store<ProtocolState>,
    private route: ActivatedRoute,
    private service: ProtocolService,
    private router: Router) { }

  ngOnInit(): void {
    this.data$ = this.getSelectedProtocol();
  }

  save(): void {
    this.store.dispatch(protocolUpdate({ data: { protocol: { ...this.model } }}));
  }

  cancel(): void {
     window.history.back();
  }

  private getSelectedProtocol(): Observable<ProtocolModel> {
    // If there is a selected protocol in the store we will take it
    // If not we will take the param id from URL and call the backend
    const params$ = this.route.params;
    const selected$ = this.store.pipe(select(protocolSelector.protocolSelected))
      .pipe(tap(protocol => {
        if (protocol?.id != null) {
          this.fields = this.service.buildForm(protocol, false);
          this.model = { ...protocol };
          this.form.markAllAsTouched();
        }
      }));

    return combineLatest([selected$, params$]).pipe(map(([selected, params]) => {
      if (selected?.id == null || selected?.id !== Number(params.id)) {
        this.store.dispatch(protocolById({ id: Number(params.id) }));
      }
      return selected;
    }));
  }

}
