import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { SubCategoryModel } from '../../models/sub-category.model';
import { OptionTypeService } from '../../services/option-type.service';

@Component({
  selector: 'app-add-edit-findings',
  templateUrl: './add-edit-findings.component.html',
  styleUrls: ['./add-edit-findings.component.scss']
})
export class AddEditFindingsComponent implements OnInit {
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubCategoryModel,
    private dialogRef: MatDialogRef<AddEditFindingsComponent>,
    private service: OptionTypeService) { }

  ngOnInit(): void {
    this.fields = this.service.buildSubCategoryForm({ ...this.data });
  }

  save(): void {
    this.dialogRef.close(this.data);
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
