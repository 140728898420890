<div style="min-width: 500px;" #dialogRef>
    <div class="modal-header">
        <span mat-dialog-title>Edit User</span>
    </div>

    <div class="modal-body">
        <mat-dialog-content class="mat-typography" style="min-height: 250px;">
            <div class="row mt-3">
                <span>{{ data.userData.firstName }} {{ data.userData.lastName }}</span>
            </div>

            <div class="row mt-3">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Add Role</mat-label>
                    <mat-select matNativeControl [formControl]="userRole">
                        <ng-container>
                            <mat-option value="Admin">Admin</mat-option>
                            <mat-option value="Reader">Reader</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-dialog-content>
    </div>
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close color="warn">Close</button>
        <button mat-stroked-button color="accent" [disabled]="userRole.pristine" (click)="editUserRole()">Submit</button>
    </mat-dialog-actions>
</div>