<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content class="mat-typography mb-5">
    {{ data.description }}
</mat-dialog-content>
<mat-dialog-actions class="w-100 d-flex justify-content-end">
    <button mat-stroked-button color="primary" (click)="close()">
        No
    </button>
    <button mat-stroked-button color="warn" (click)="confirm()">
        Yes
    </button>
</mat-dialog-actions>