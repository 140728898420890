import { Component, OnDestroy } from '@angular/core';
import { AppState } from '@core/app-state/reducers';
import { authSelector } from '@core/services/auth/state/authentication.selectors';
import { optionsLoad } from '@features/admin/option-management/store/option.actions';
import { Store } from '@ngrx/store';
import { AnimationsService } from '@shared/services/animations/animations.service';
import { routeAnimations } from '@shared/services/animations/route.animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [routeAnimations],
})
export class HomeComponent implements OnDestroy {
  private subs$: Subscription[] = [];

  constructor(private animationService: AnimationsService, private store: Store<AppState>) {
    // Loading Initial Application State
    // Items here are likely required system wide
    this.animationService.updateRouteAnimationType(true, true);

    this.subs$.push(
      this.store.select(authSelector.accessToken).subscribe(data => {
        if (data != null || data !== '' && data) {
          this.store.dispatch(optionsLoad());
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
  }
}
