import { Component, OnInit } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  constructor() { }
}
