import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProtocolState } from './protocol.reducers';

export const protocolSelector = {
    /**
     * Returns all protocols in the store
     *
     * @return ProtocolState | [].
     *
     */
    allProtocols: createSelector(createFeatureSelector<ProtocolState>('protocols'),
        (state: ProtocolState) => state
    ),

    /**
     * Returns protocol by id from store if found
     *
     *
     * @param number id of option.
     *
     * @return ProtocolModel | undefined.
     *
     */
    protocolById: (id: number) => createSelector(createFeatureSelector<ProtocolState>('protocols'),
        (state: ProtocolState) => state.protocols.find(protocol => protocol.id === id)
    ),

    /**
     * Returns selected protocol in the store
     *
     * @return ProtocolModel | undefined.
     *
     */
    protocolSelected: createSelector(createFeatureSelector<ProtocolState>('protocols'),
        (state: ProtocolState) => state.selectedProtocol
    )
};
