<div id="top" fxLayout="column" fxFlexFill>
  <app-header></app-header>
  <!-- <header class="header-background"></header> -->

  <div fxFlex class="content mt-5" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig?.path">
    <main style="padding-bottom: 52px; overflow: auto">
      <div class="container">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </main>
  </div>
  <app-scroll-to-top><!-- add id="top" --></app-scroll-to-top>
  <app-footer></app-footer>
</div>
