import { ProtocolHistoryModalComponent } from './protocol-history-modal/protocol-history-modal.component';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ProtocolModel } from '../models/protocol.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-protocol-history',
  templateUrl: './protocol-history.component.html',
  styleUrls: ['./protocol-history.component.scss']
})
export class ProtocolHistoryComponent {
  @Input() history$!: Observable<ProtocolModel>;
  displayedColumns: string[] = ['investigator', 'created', 'createdBy', 'actions'];

  constructor(public dialog: MatDialog) { }

  view(model: ProtocolModel): void {
    this.dialog.open(ProtocolHistoryModalComponent, { data: model });
  }

}
