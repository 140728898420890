<div class="search-div">
    <mat-form-field class="search-form-field" floatLabel="never">
      <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off"
        (keyup)="applyFilter()">
      <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-stroked-button class="mb-3" [ngClass]="routeAnimationsElements" color="accent" (click)="openModal({})">
        <mat-icon>add</mat-icon>Add Special Finding
      </button>
  </div>
<div class="table-container mt-3">
    <table mat-table *ngIf="data$ | async as data" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disableClear]="true">Title</th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disableClear]="true">Category</th>
            <td mat-cell *matCellDef="let element">
                {{ element.category }}
            </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                {{ element.isActive ? 'Active' : 'Inactive' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <ng-container>
                    <button class="mr-2" mat-stroked-button color="accent"
                        (click)="openModal(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headElements"></tr>
        <tr mat-row *matRowDef="let row; columns: headElements;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
</div>
