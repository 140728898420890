import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this.oidcSecurityService !== undefined) {
      const token = this.oidcSecurityService.getAccessToken();
      if (token !== '') {
        const tokenValue = 'Bearer ' + token;
        requestToForward = req.clone({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          setHeaders: { Authorization: tokenValue },
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.debug('OidcSecurityService undefined: NO auth header!');
    }

    return next.handle(requestToForward);
  }
}
