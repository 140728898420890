<table *ngIf="history$ | async as history" mat-table [dataSource]="history.protocolHistories" class="w-100">
  <ng-container matColumnDef="investigator">
    <th mat-header-cell *matHeaderCellDef> Investigator </th>
    <td mat-cell *matCellDef="let element"> {{ element?.investigator?.name }} </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{ element?.created | date:'shortDate' }} </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef> CreatedBy </th>
    <td mat-cell *matCellDef="let element"> {{ element?.createdBy || '' }} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element"><button mat-stroked-button color="accent"
        (click)="view(element)">View</button></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>