<!-- Footer -->
<footer class="page-footer">
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    © {{ today | date: 'yyyy' }} Copyright
    <a href="https://unthsc.edu/"> University of North Texas Health Science Center</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
