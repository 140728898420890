import { LogLevel } from 'angular-auth-oidc-client';

const clientId = 'irb';
const scope = 'openid profile offline_access';

export const applicationConfig = {
  defaults: {
    title: 'IRB',
    shortTitle: 'IRB',
    clientId,
  },
  production: {
    debug: LogLevel.None,
    clientId,
    identityUrl: 'https://irb.unthsc.edu',
    clientUrl: 'https://irb.unthsc.edu',
    scope,
    apiUri: 'https://irb.unthsc.edu/api',
    azureStorage: '',
  },
  uat: {
    debug: LogLevel.Error,
    clientId,
    identityUrl: 'https://unthsc-irb-uat.azurewebsites.net',
    clientUrl: 'https://unthsc-irb-uat.azurewebsites.net',
    scope,
    apiUri: 'https://unthsc-irb-uat.azurewebsites.net/api',
    azureStorage: '',
  },
  qa: {
    debug: LogLevel.Error,
    clientId,
    identityUrl: 'https://unthsc-irb-qa.azurewebsites.net',
    clientUrl: 'https://unthsc-irb-qa.azurewebsites.net',
    scope,
    apiUri: 'https://unthsc-irb-qa.azurewebsites.net/api',
    azureStorage: '',
  },
  staging: {
    debug: LogLevel.Error,
    clientId,
    identityUrl: 'https://unthsc-irb-staging.azurewebsites.net',
    clientUrl: 'https://unthsc-irb-staging.azurewebsites.net',
    scope,
    apiUri: 'https://unthsc-irb-staging.azurewebsites.net/api',
    azureStorage: '',
  },
  development: {
    debug: LogLevel.Error,
    clientId,
    identityUrl: 'https://localhost/irb',
    clientUrl: 'https://localhost:4200',
    scope,
    apiUri: 'https://localhost/irb/api',
    azureStorage: 'https://hsccdn.blob.core.windows.net/afms-dev',
  },
  local: {
    debug: LogLevel.Error,
    clientId,
    identityUrl: 'https://localhost/irb',
    clientUrl: 'https://localhost:4200',
    scope,
    apiUri: 'https://localhost/irb/api',
    azureStorage: '',
  },
};
