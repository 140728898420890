import { Component, OnInit } from '@angular/core';
import { AppState } from '@core/app-state/reducers';
import { IdentityUser } from '@core/services/auth/models/application-user.model';
import { AuthService } from '@core/services/auth/services/auth.service';
import { authSelector } from '@core/services/auth/state/authentication.selectors';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  data$: Observable<{ user: IdentityUser; isAdmin: boolean; isWarden: boolean }>;

  navigation = [
    { link: 'home', label: 'Protocols' },
    // { link: 'statusReport', label: 'Reports' },
  ];

  constructor(private store: Store<AppState>, private authService: AuthService) {
    this.data$ = new Observable();
  }

  ngOnInit(): void {
    const userData$ = this.store.select(authSelector.userData);
    const isAdmin$ = this.store.select(authSelector.isAdmin);
    const isWarden$ = this.store.select(authSelector.isWarden);

    this.data$ = combineLatest([userData$, isAdmin$, isWarden$]).pipe(
      map(([userData, admin, warden]) => ({ user: userData, isAdmin: admin, isWarden: warden })));

    // DEBUG
    // this.data$ = userData$.pipe(map(x => ({ user: x, isAdmin: false, isWarden: false })));
  }

  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
  }

  copyToClipboard(): void {
    const token = this.authService.getBearerToken();
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/plain', token);
      e.preventDefault();
      document.removeEventListener('copy', (ev: ClipboardEvent) => {
        ev.preventDefault();
      });
    });
    document.execCommand('copy');
  }
}
