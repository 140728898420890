import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { SearchModel } from "@shared/models/search.model";
import { ApiService } from "@shared/services/api/api.service";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from "rxjs/operators";
import { StatusReportListModel } from "./models/status-report-list-model";

export class StatusReportDataSource implements DataSource<StatusReportListModel> {
    private reportSubjects = new BehaviorSubject<StatusReportListModel[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public totalCount = new BehaviorSubject<any>(0);
    public output = new BehaviorSubject<any>([]);
    public loading$ = this.loadingSubject.asObservable();
    constructor(private apiService: ApiService) {

    }
    connect(collectionViewer: CollectionViewer): Observable<StatusReportListModel[]> {
        return  this.reportSubjects.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.reportSubjects.complete();
        this.loadingSubject.complete();
    }

    loadData(filterQuery = '', sortColumn = '', sortDirection = '', pageIndex = 0, pageSize = 10)  {
        const options = new SearchModel();
        options.filter.search = filterQuery;
        options.order = sortDirection == 'asc'? 'asc': 'desc';
        options.pageIndex = pageIndex;
        options.pageSize = pageSize;
        options.sort = sortColumn;      
        this.loadingSubject.next(true);
        this.apiService.searchReport<StatusReportListModel>('report', options.filter.search, options.sort, options.order, options.pageIndex, options.pageSize).pipe(
            catchError(() => of([])), 
            finalize(() => this.loadingSubject.next(false))
        ).subscribe((x: any) => {
                this.reportSubjects.next(x!.statusReports);
                this.totalCount.next(x!.totalCount);
                this.output.next(x!.output);

        });
     
    }



}