import { usersLoaded } from './user.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../models/user.model';

export const USER_FEATURE_KEY = 'users';

export interface UserState {
    users: User[];
}

export const initialUserState: UserState = {
    users: []
};

export const userReducer = createReducer(
    initialUserState,

    on(usersLoaded, (state, { users }) => ({ ...state, users }))
);

export const reducer = (state: UserState, action: Action) => userReducer(state, action);
