import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OptionManagementComponent } from '@features/admin/option-management/components/option-management.component';
import { UserManagementComponent } from '@features/admin/user-management/components/user-management.component';
import { ProtocolModel } from '@features/protocols/models/protocol.model';
import { ProtocolAddComponent } from '@features/protocols/protocol-add/protocol-add.component';
import { ProtocolEditComponent } from '@features/protocols/protocol-edit/protocol-edit.component';
import { ProtocolsListComponent } from '@features/protocols/protocols-list/protocols-list.component';
import { StatusReportComponent } from '@features/reports/report/statusreport/statusreport.component';
import { AutoLoginComponent } from '@shared/components/auto-login/auto-login.component';

import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'home', component: ProtocolsListComponent },
  { path: 'reports', component: ProtocolsListComponent },
  { path: 'report', component: StatusReportComponent },
  { path: 'admin/users', component: UserManagementComponent },
  { path: 'admin/options', component: OptionManagementComponent },
  { path: 'protocol/add', component: ProtocolAddComponent },
  { path: 'protocol/:id', component: ProtocolEditComponent, data: { protocol: { } as ProtocolModel } },
  { path: 'autologin', component: AutoLoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: ProtocolsListComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
