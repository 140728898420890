import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() showHelp = false;
  @Input() helpText: string | undefined;
  @Input() showLogo = true;

  constructor() { }

  ngOnInit(): void {
  }

}
