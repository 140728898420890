import { Injectable } from '@angular/core';
import { ClientConfig } from './models/config.model';
import { ClientDefaults } from './models/client-defaults.model';
import { applicationConfig } from './app.config';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';

declare const window: Window;

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  systemConfiguration = {} as ClientConfig;
  defaults = {} as ClientDefaults;

  constructor() {
    this.loadConfigurations();
  }

  getConfig(): OpenIdConfiguration {
    var config = {
      authority: this.systemConfiguration.identityUrl,
      authWellknownEndpointUrl: `${this.systemConfiguration.identityUrl}/.well-known/openid-configuration`,
      redirectUrl: this.systemConfiguration.clientUrl,
      clientId: this.systemConfiguration.clientId,
      scope: this.systemConfiguration.scope,
      responseType: 'code',
      silentRenew: true,
      silentRenewUrl: `${this.systemConfiguration.clientUrl}/silent-renew.html`,
      postLogoutRedirectUri: `${this.systemConfiguration.clientUrl}`,
      renewTimeBeforeTokenExpiresInSeconds: 10,
      useRefreshToken: true,
      ignoreNonceAfterRefresh: true,
      maxIdTokenIatOffsetAllowedInSeconds:600,
      logLevel: this.systemConfiguration.debug
    };
    return config;
  }

  loadConfigurations(): ClientConfig {
    let currentConfig: ClientConfig;
    switch (window.location.hostname) {
      case this.getHostName(applicationConfig.production.clientUrl):
        currentConfig = applicationConfig.production;
        break;
      case this.getHostName(applicationConfig.uat.clientUrl):
        currentConfig = applicationConfig.uat;
        break;
      case this.getHostName(applicationConfig.qa.clientUrl):
        currentConfig = applicationConfig.qa;
        break;
      case this.getHostName(applicationConfig.development.clientUrl):
        currentConfig = applicationConfig.development;
        break;
      case this.getHostName(applicationConfig.staging.clientUrl):
        currentConfig = applicationConfig.staging;
        break;
      default:
        currentConfig = applicationConfig.local;
    }
    this.systemConfiguration = currentConfig;
    this.defaults = applicationConfig.defaults;
    return currentConfig;
  }

  private getHostName(url: string): string {
    return url.replace('https://', '').replace('/', '');
  }
}
