import { Injectable } from '@angular/core';
import { ConfigurationService } from '@core/services/configuration/configuration.service';
import { ErrorResultModel } from '@shared/models/error-result.model';
import { SearchModel } from '@shared/models/search.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NgxNotifyService } from '../ngx-notify/ngx-notify.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl: string;
  constructor(private http: HttpClient, private configService: ConfigurationService, private notify: NgxNotifyService, private router: Router, private fb: FormBuilder) {
    this.apiUrl = this.configService.systemConfiguration.apiUri;
  }

  get<T>(endpoint: string): Observable<T> {
    return this.http.get<any>(`${this.apiUrl}/${endpoint}`).pipe(catchError((error) => this.handleError<T>(error)));
  }

  getAllById<T>(endpoint: string, id: string | number): Observable<T[]> {
    return this.http.get<T[]>(`${this.apiUrl}/${endpoint}/${id}`).pipe(catchError((error) => this.handleError<T>(error)));
  }

  search<T>(endpoint: string, options: SearchModel): Observable<T> {
    const requestUrl = `${this.apiUrl}/${endpoint}?${options.queryParams(options)}`;
    return this.http.get<any>(requestUrl).pipe(catchError((error) => this.handleError<T>(error)));
  }

  searchReport<T>(endpoint: string,filter: string, sort: string, order: string = 'asc',pageIndex: number,pageSize: number): Observable<T> {
   const requestUrl = `${this.apiUrl}/${endpoint}?${filter}&sort=${sort}&order=${order}&pageIndex=${pageIndex + 1}&pageSize=${pageSize}`;
   return this.http.get<any>(requestUrl).pipe(catchError(error => this.handleError<T>(error)));
  }

  create<T>(endpoint: string, model: T): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}/${endpoint}`, model).pipe(catchError((error) => this.handleError<T>(error)));
  }

  getById<T>(endpoint: string, id: string | number, options = {}): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}/${endpoint}/${id}`, options).pipe(catchError((error) => this.handleError<T>(error)));
  }

  update<T>(endpoint: string, model: T): Observable<T> {
    return this.http.put<T>(`${this.apiUrl}/${endpoint}`, model).pipe(catchError((error) => this.handleError<T>(error)));
  }

  delete<T>(endpoint: string, id: number): Observable<T> {
    return this.http.delete<T>(`${this.apiUrl}/${endpoint}/${id}`).pipe(catchError((error) => this.handleError<T>(error)));
  }

  downloadExcelFile<T>(endpoint: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${endpoint}`, { responseType: 'blob' })
      .pipe(catchError(error => this.handleError<T>(error)));
  }


  private handleError<T>(error: any): Observable<never> {
    if (error.status === 401) {
      this.router.navigate(['/autologin']);
      return throwError(error);
    }

    if (error.status === 500) {
      this.notify.error('An error was detected. Please refresh your browser and try again.');
      return throwError(error);
    }

    if (error.error?.errors != null) {
      this.notify.error('Please check your submission and try again.');
      return throwError(error);
    }

    const resultModel = { ...error.error } as ErrorResultModel<T>;
    this.notify.error(resultModel.extendedMessage);
    return throwError(error);
  }
}
