import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@core/services/error-handler/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '@shared/services/api/api.service';
import { NgxNotifyService } from '@shared/services/ngx-notify/ngx-notify.service';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProtocolListModel } from '../models/protocol-list.model';
import { ProtocolModel } from '../models/protocol.model';
import * as protocolActions from './protocol.actions';

@Injectable()
export class ProtocolEffects {

    protocolSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(protocolActions.protocolSearch),
            switchMap((actions) =>
                this.apiService.search<ProtocolListModel>(this.endpoint, actions.options).pipe(
                    map(res =>
                        protocolActions.protocolSearchLoaded({
                            protocols: res.protocols,
                            selectedProtocol: {} as ProtocolModel,
                            count: res.count
                        }),
                        catchError((err) => {
                            this.notify.error(this.errorHandler.handleError(err));
                            return EMPTY;
                        })
                    )
                ),
            )
        )
    );

    protocolById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(protocolActions.protocolById),
            switchMap((actions) =>
                this.apiService.getById<ProtocolModel>(this.endpoint, actions.id).pipe(
                    map(res => protocolActions.protocolByIdLoaded({ data: { protocol: res } })),
                    catchError((err) => {
                        this.notify.error(this.errorHandler.handleError(err));
                        return EMPTY;
                    })
                ),
            )
        ),
    );

    protocolCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(protocolActions.protocolCreate),
            switchMap((actions) =>
                this.apiService.create<ProtocolModel>(this.endpoint, actions.data.protocol).pipe(
                    map(res => {
                        this.notify.success(`Created ${res.studyTitle}`);
                        this.router.navigate(['/protocol', res.id]);
                        return protocolActions.protocolCreateLoaded({ data: { protocol: res } });
                    }),
                    catchError((err) => {
                        this.notify.error(this.errorHandler.handleError(err));
                        return EMPTY;
                    })
                ),
            )
        ),
    );

    protocolUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(protocolActions.protocolUpdate),
            switchMap((actions) =>
                this.apiService.update<ProtocolModel>(this.endpoint, actions.data.protocol).pipe(
                    map(res => {
                        this.notify.success(`Updated ${res.studyTitle}`);
                        return protocolActions.protocolUpdateLoaded({ data: { protocol: res } });
                    }),
                    catchError((err) => {
                        this.notify.error(this.errorHandler.handleError(err));
                        return EMPTY;
                    })
                ),
            )
        ),
    );

    private endpoint = 'protocol';

    constructor(
        private actions$: Actions,
        private notify: NgxNotifyService,
        private apiService: ApiService,
        private errorHandler: ErrorHandlerService,
        private router: Router) { }
}
