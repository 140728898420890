import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '../models/user.model';
import { UserState } from './user.reducers';

export const userSelector = {
    /**
     * Returns all users in the store
     *
     * @return UserState | [].
     *
     */
    allUsers: createSelector(createFeatureSelector<UserState>('users'), (state: UserState) => state.users),

    /**
     * Returns user from store if found
     *
     *
     * @param string EUID of user.
     *
     * @return User | undefined.
     *
     */
    userByUserId: (euid: string) =>
        createSelector(createFeatureSelector<UserState>('users'), (state: UserState) => state.users.find((user: User) =>
            user.userData.userName === euid))
};
