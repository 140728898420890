import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@core/app-state/reducers';
import { Store } from '@ngrx/store';
import { NgxNotifyService } from '@shared/services/ngx-notify/ngx-notify.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IdentityUser } from '../models/application-user.model';
import { logout } from '../state/authentication.actions';
import * as AuthActions from '../state/authentication.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private email!: string;
  private currentUserId!: string;
  private userClaims!: IdentityUser;

  constructor(
    private oidcService: OidcSecurityService,
    private store: Store<AppState>,
    private router: Router,
    private notify: NgxNotifyService) {
    this.initAuthConfig();
  }

  login(): void {
    this.oidcService.authorize();
  }

  logout(): void {
    this.store.dispatch(logout());
    this.oidcService.logoffAndRevokeTokens().subscribe();
  }

  getBearerToken(): string {
    // console.log(`Bearer ${this.oidcService.getToken()}`);
    return `Bearer ${this.oidcService.getAccessToken()}`;
  }

  getToken(): string {
    // console.log(`Bearer ${this.oidcService.getToken()}`);
    return `${this.oidcService.getAccessToken()}`;
  }

  getUserEmail(): string {
    return this.email;
  }

  getCurrentUserId(): number {
    return +this.currentUserId;
  }

  getUserClaims() : IdentityUser {
    return this.userClaims;
 }

  private initAuthConfig(): void {
    const auth$ = combineLatest([this.oidcService.checkAuth(), this.oidcService.userData$, this.oidcService.isAuthenticated$]);

    auth$
      .pipe(
        take(1),
        map((results) => {
          const auth: boolean = results[0]?.isAuthenticated;
          const data: IdentityUser = results[1]?.userData;
          const isAuthenticated: boolean = results[2]?.isAuthenticated;
          this.userClaims = data;
          this.email = data?.email;
          this.currentUserId = data?.sub;

          if (!auth && !isAuthenticated && data == null) {
            if ('/autologin' !== window.location.pathname) {
              this.write('redirect', window.location.pathname);
              this.router.navigate(['/autologin']);
            }
          } else if (auth && isAuthenticated && data != null) {
            const loginAction = AuthActions.login({
              userData: data,
              isWarden: data?.role_warden != null,
              isAdmin: data?.role_admin != null || data?.role_warden != null,
              accessToken: this.oidcService.getAccessToken(),
            });

            this.store.dispatch(loginAction);
            // this.navigateToStoredEndpoint();
          } else {
            // Something really bad has happened
            // Login process completely failed!
            sessionStorage.clear();
            localStorage.clear();
            this.oidcService.checkAuthIncludingServer();
            this.notify.error('Unauthorized');
          }
        })
      )
      .subscribe();
  }

  private navigateToStoredEndpoint(): void {
    const path = this.read('redirect');

    if (this.router.url === path) {
      return;
    }

    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([path]);
    }
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return;
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
