import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { User } from '../../models/user.model';
import { UserStoreService } from '../../services/user-store.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  userRole: FormControl = new FormControl('', Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User,
    private userService: UserStoreService,
    private dialogRef: MatDialogRef<UserEditComponent>) { }

  ngOnInit(): void {
    this.userRole.setValue(this.data.isAdmin ? 'Admin' : 'Reader');
  }

  editUserRole(): void {
    const euid = this.data.userData.userName;
    const currentRole = this.data.isAdmin ? 'Admin' : 'Reader';

    if (this.userRole.value !== currentRole) {
      this.userService.removeUserRole(euid, currentRole);
      setTimeout(() => this.userService.addUserRole(euid, this.userRole.value), 500);
    }

    this.dialogRef.close();
  }
}
