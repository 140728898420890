import { SortDirection } from '@angular/material/sort';

export interface SearchFilter {
    [key: string]: string | number | Date | undefined;
    studyTitle?: string;
    ntrIrbCoordinatorId?: number;
    investigatorId?: number;
    statusId?: number;
    approvalThrough?:string | Date | undefined;
    irbNumber?:string | number;
    irbNetNumber?:string | number;
    sendNextReview?:string | Date | undefined;
}

export class SearchModel {
    filter: SearchFilter;
    sort: string;
    order: 'asc' | 'desc' | SortDirection;
    pageIndex: number;
    pageSize: number;

    constructor(filter?: SearchFilter, sort?: string, order?: 'asc' | 'desc', pageIndex?: number, pageSize?: number, active?: boolean) {
        this.filter = filter == null ? {} as SearchFilter : filter;
        this.sort = sort == null ? 'irbnumber' : sort;
        this.order = order == null ? 'desc' : order;
        this.pageIndex = pageIndex == null ? 0 : pageIndex;
        this.pageSize = pageSize == null ? 10 : pageSize;
    }

    queryParams = (options: this): string => `${this.buildFilters(options.filter)}&sort=${options.sort}` +
        `&order=${options.order}&pageIndex=${options.pageIndex + 1}&pageSize=${options.pageSize}`;

    private buildFilters(filters: SearchFilter): string {
        if (Object.keys(filters).length === 0) {
            return 'studyTitle=';
        }
        return Object.keys(filters).map(key => `&${key}=${filters[key]}`).join(',').replace(/,/g, '');//Replacing all commas
    }
}
