import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OptionType } from '../models/option-type.enum';
import { OptionState } from './option.reducers';

export const optionSelector = {
  /**
   * Returns all options in the store
   *
   * @return OptionState | [].
   *
   */
  allOptions: createSelector(createFeatureSelector<OptionState>('options'), (state: OptionState) => state),

  /**
   * Returns all active options in the store
   *
   * @return OptionState | [].
   *
   */
  activeOptions: createSelector(createFeatureSelector<OptionState>('options'),
    (state: OptionState) => Object.keys(state).filter(x => state[x].isActive)
  ),

  /**
   * Returns option by id from store if found
   *
   *
   * @param number id of option.
   *
   * @return OptionModel | undefined.
   *
   */
  selectOptionById: (id: number) => createSelector(
    createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.options.find(option => option.id === id)
  ),

  /**
   * Returns all options by type
   *
   *
   * @param OptionType enum of OptionModel type.
   * @param Active boolean.
   *
   * @return OptionModel[] | undefined.
   *
   */
  optionsByType: (type: OptionType, active = true) => createSelector(
    createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.options.filter(option => option.optionType === type)
  ),

  /**
   * Returns active options by type
   *
   *
   * @param OptionType enum of OptionModel type.
   * @param Active boolean.
   *
   * @return OptionModel[] | undefined.
   *
   */
  activeOptionsByType: (type: OptionType, active = true) => createSelector(createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.options.filter(option => option.optionType === type && option.isActive === active)
  ),

  /**
   * Returns Special Findings
   *
   * @return SubCategoryModel[] | undefined.
   *
   */
  specialFindings: createSelector(createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.specialFindings
  ),

  /**
   * Returns Categories
   *
   * @return Category[] | undefined.
   *
   */
  categories: createSelector(createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.categories
  ),

  /**
   * Returns Special Finding by id from store if found
   *
   *
   * @param number id of option.
   *
   * @return SubCategoryModel | undefined.
   *
   */
  specialFindingsById: (id: number) => createSelector(createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.specialFindings.filter(x => x.id === id)
  ),

  /**
   * Returns Category by id from store if found
   *
   *
   * @param number id of option.
   *
   * @return CategoryModel | undefined.
   *
   */
  categoriesById: (id: number) => createSelector(
    createFeatureSelector<OptionState>('options'),
    (state: OptionState) => state.categories.filter(x => x.id === id)
  )
};
