import { createAction, props } from '@ngrx/store';
import { SearchModel } from '@shared/models/search.model';
import { ProtocolModel } from '../models/protocol.model';
import { ProtocolState } from './protocol.reducers';

export const protocolSearch = createAction('[PROTOCOL_ACTION] Search Protocols', props<{ options: SearchModel }>());
export const protocolSearchLoaded = createAction('[PROTOCOL_ACTION] Search Protocols Loaded', props<ProtocolState>());
export const protocolById = createAction('[PROTOCOL_ACTION] Protocol By Id', props<{ id: number }>());
export const protocolByIdLoaded = createAction('[PROTOCOL_ACTION] Protocol By Id Loaded', props<{ data: { protocol: ProtocolModel }}>());
export const protocolCreate = createAction('[PROTOCOL_ACTION] Create Protocol', props<{ data: { protocol: ProtocolModel }}>());
export const protocolCreateLoaded = createAction('[PROTOCOL_ACTION] Create Protocol Loaded', props<{ data: { protocol: ProtocolModel }}>());
export const protocolUpdate = createAction('[PROTOCOL_ACTION] Update Protocol', props<{ data: { protocol: ProtocolModel }}>());
export const protocolUpdateLoaded = createAction('[PROTOCOL_ACTION] Update Protocol Loaded', props<{ data: { protocol: ProtocolModel }}>());
