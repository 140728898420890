import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NgxNotifyService {
  private duration = 5000;

  constructor(private readonly snackBar: MatSnackBar, private readonly zone: NgZone) {}

  default(message: string): void {
    this.show(message, {
      duration: this.duration,
      panelClass: ['default-notification-overlay', 'transparent'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  info(message: string): void {
    this.show(message, {
      duration: this.duration,
      panelClass: ['info-notification-overlay', 'transparent'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  success(message: string): void {
    this.show(message, {
      duration: this.duration,
      panelClass: ['success-notification-overlay', 'transparent'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  warn(message: string): void {
    this.show(message, {
      duration: this.duration,
      panelClass: ['warning-notification-overlay', 'transparent'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  error(message: string): void {
    this.show(message, {
      duration: this.duration,
      panelClass: ['error-notification-overlay', 'transparent'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  private show(message: string, configuration: MatSnackBarConfig): void {
    this.zone.run(() => this.snackBar.open(message, '', configuration));
  }
}
