import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProtocolEffects } from './protocol.effects';
import { PROTOCOL_FEATURE_KEY, protocolReducer } from './protocol.reducers';

@NgModule({
    imports: [
        StoreModule.forFeature(PROTOCOL_FEATURE_KEY, protocolReducer),
        EffectsModule.forFeature([ProtocolEffects])
    ]
})

export class ProtocolStoreModule {}
