import { createAction, props } from '@ngrx/store';

import { CategoryModel } from '../models/category.model';
import { OptionModel } from '../models/option.model';
import { SubCategoryModel } from '../models/sub-category.model';
import { OptionState } from './option.reducers';

export const optionsLoad = createAction('[OPTIONS_ACTION] Get Options');
export const optionsLoaded = createAction('[OPTIONS_ACTION] Options Loaded', props<OptionState>());
export const optionsCreate = createAction('[OPTIONS_ACTION] Create Option', props<{ data: { option: OptionModel } }>());
export const optionsUpdate = createAction('[OPTIONS_ACTION] Update Option', props<{ data: { option: OptionModel } }>());

export const optionsCreateCategory =
    createAction('[OPTIONS_ACTION] Create Category Option', props<{ data: { option: CategoryModel } }>());
export const optionsUpdateCategory =
    createAction('[OPTIONS_ACTION] Update Category Option', props<{ data: { option: CategoryModel } }>());

export const optionsCreateSpecialFinding =
    createAction('[OPTIONS_ACTION] Create Special Finding Option', props<{ data: { option: SubCategoryModel } }>());
export const optionsUpdateSpecialFinding =
    createAction('[OPTIONS_ACTION] Update Special Finding Option', props<{ data: { option: SubCategoryModel } }>());
