import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProtocolModel } from '@features/protocols/models/protocol.model';
import { ProtocolService } from '@features/protocols/services/protocol.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-protocol-history-modal',
  templateUrl: './protocol-history-modal.component.html',
  styleUrls: ['./protocol-history-modal.component.scss']
})
export class ProtocolHistoryModalComponent implements OnInit {
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  model = {} as ProtocolModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProtocolModel, private service: ProtocolService) { }

  ngOnInit(): void {
    this.fields = this.service.buildForm(this.data, true);
    this.model = { ...this.data };
  }

}
