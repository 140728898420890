import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@shared/models/confirm-dialog-data';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '../models/user.model';
import { UserStoreService } from '../services/user-store.service';
import { usersLoad } from '../store/user.actions';
import { UserState } from '../store/user.reducers';
import { userSelector } from '../store/user.selectors';
import { UserAddComponent } from './user-add/user-add.component';
import { UserEditComponent } from './user-edit/user-edit.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  data$!: Observable<User[]>;
  headElements = ['firstName', 'lastName', 'roles', 'actions'];
  isMobile = false;
  sub$: Subscription = new Subscription();
  dataSource = new MatTableDataSource<User>();

  constructor(
    private store: Store<UserState>,
    private dialog: MatDialog,
    private userService: UserStoreService) {}

  ngOnInit(): void {
    this.store.dispatch(usersLoad());
    this.data$ = this.store.pipe(select(userSelector.allUsers)).pipe(tap(users => this.dataSource.data = users));
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
  }

  add(): void {
    this.dialog.open(UserAddComponent);
  }

  edit(data: User): void {
    this.dialog.open(UserEditComponent, { data });
  }

  remove(user: User): void {
    const fullName = `${user.userData.firstName} ${user.userData.lastName}`;

    const data: ConfirmDialogData = {
      title: 'Remove User',
      description: `Are you sure you would like to remove ${fullName} from the IRB system?`
    };
    this.sub$ = this.dialog.open(ConfirmationDialogComponent, { data }).afterClosed()
      .subscribe(res => {
        if (res) {
          const role = user.isAdmin ? 'Admin' : 'Reader';
          this.userService.removeUserRole(user.userData.userName, role);
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
