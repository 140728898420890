import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState } from './authentication.reducer';

export const authSelector = {
    selectAuthState: createFeatureSelector<AuthState>('authentication'),
    isAuthenticated: createSelector(createFeatureSelector<AuthState>('authentication'), (auth) => !!auth?.accessToken || false),
    userData: createSelector(createFeatureSelector<AuthState>('authentication'), (auth) => auth?.userData || null),
    accessToken: createSelector(createFeatureSelector<AuthState>('authentication'), (auth) => auth?.accessToken || null),
    isWarden: createSelector(createFeatureSelector<AuthState>('authentication'), (auth) => !!auth?.isWarden || false),
    isAdmin: createSelector(createFeatureSelector<AuthState>('authentication'), (auth) => !!auth?.isAdmin || false)
};
