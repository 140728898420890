// eslint-disable-next-line no-shadow
export enum OptionType {
  school = 'School',
  department = 'Department',
  institution = 'Institution',
  typeOfResearch = 'TypeOfResearch',
  sponsorType = 'SponsorType',
  typeOfReview = 'TypeOfReview',
  status = 'Status',
  reviewCycle = 'ReviewCycle',
  nTRIRBCoordinator = 'NTRIRBCoordinator',
  investigator = 'Investigator'
}
