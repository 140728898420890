import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '@shared/services/api/api.service';
import { NgxNotifyService } from '@shared/services/ngx-notify/ngx-notify.service';
import { combineLatest, EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CategoryModel } from '../models/category.model';
import { OptionModel } from '../models/option.model';
import { SubCategoryModel } from '../models/sub-category.model';
import * as optionActions from './option.actions';

@Injectable()
export class OptionEffects {
  optionsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsLoad),
      switchMap(() =>
        combineLatest([this.getOptions, this.getCategories]).pipe(
          map(([opts, cats]) => {
            const sub = [] as SubCategoryModel[];
            cats.forEach(x => x.subCategories.forEach(y => {
              y.category = x.name;
              sub.push(y);
            }));
            sub.sort((a, b) => a.name.localeCompare(b.name));
            return optionActions.optionsLoaded({ specialFindings: sub, categories: cats, options: opts });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  optionsCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsCreate),
      switchMap((action) =>
        this.apiService.create(this.optionEndpoint, action.data.option).pipe(
          map(() => {
            this.notify.success('Option added');
            return optionActions.optionsLoad();
          }),
          catchError((err) => {
            this.notify.error(this.errorHandler.handleError(err));
            return EMPTY;
          })
        )
      )
    )
  );

  optionsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsUpdate),
      switchMap((action) =>
        this.apiService.update(this.optionEndpoint, action.data.option).pipe(
          map(() => {
            this.notify.success('Option updated');
            return optionActions.optionsLoad();
          }),
          catchError((err) => {
            this.notify.error(this.errorHandler.handleError(err));
            return EMPTY;
          })
        )
      )
    )
  );

  optionsCreateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsCreateCategory),
      switchMap((action) =>
        this.apiService.create(this.categoriesEndpoint, action.data.option).pipe(
          map(() => {
            this.notify.success('Category added');
            return optionActions.optionsLoad();
          }),
          catchError((err) => {
            this.notify.error(this.errorHandler.handleError(err));
            return EMPTY;
          })
        )
      )
    )
  );

  optionsUpdateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsUpdateCategory),
      switchMap((action) =>
        this.apiService.update(this.categoriesEndpoint, action.data.option).pipe(
          map(() => {
            this.notify.success('Category updated');
            return optionActions.optionsLoad();
          }),
          catchError((err) => {
            this.notify.error(this.errorHandler.handleError(err));
            return EMPTY;
          })
        )
      )
    )
  );

  optionsCreateSpecialFinding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsCreateSpecialFinding),
      switchMap((action) =>
        this.apiService.create(this.specialFindingsEndpoint, action.data.option).pipe(
          map(() => {
            this.notify.success('Special finding added');
            return optionActions.optionsLoad();
          }),
          catchError((err) => {
            this.notify.error(this.errorHandler.handleError(err));
            return EMPTY;
          })
        )
      )
    )
  );

  optionsUpdateSpecialFinding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionActions.optionsUpdateSpecialFinding),
      switchMap((action) =>
        this.apiService.update(this.specialFindingsEndpoint, action.data.option).pipe(
          map(() => {
            this.notify.success('Special finding updated');
            return optionActions.optionsLoad();
          }),
          catchError((err) => {
            this.notify.error(this.errorHandler.handleError(err));
            return EMPTY;
          })
        )
      )
    )
  );


  private optionEndpoint = 'option';
  private specialFindingsEndpoint = 'subcategory';
  private categoriesEndpoint = 'category';
  private getOptions = this.apiService.get<OptionModel[]>(`${this.optionEndpoint}/getalloptions?onlyActiveOptions=false`);
  private getCategories = this.apiService.get<CategoryModel[]>('category');

  constructor(
    private actions$: Actions,
    private notify: NgxNotifyService,
    private apiService: ApiService,
    private errorHandler: ErrorHandlerService) { }
}
