import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SubCategoryModel } from '../../models/sub-category.model';
import { optionsCreateSpecialFinding, optionsUpdateSpecialFinding } from '../../store/option.actions';
import { OptionState } from '../../store/option.reducers';
import { AddEditFindingsComponent } from '../add-edit-findings/add-edit-findings.component';
import { optionSelector } from './../../store/option.selectors';

@Component({
  selector: 'app-findings',
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.scss']
})
export class FindingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  data$!: Observable<OptionState | undefined>;
  dataSource = new MatTableDataSource<SubCategoryModel>();
  subs$: Subscription[] = [];
  headElements = ['name', 'category', 'isActive', 'actions'];
  searchKey!: string;

  constructor(private dialog: MatDialog, private store: Store<OptionState>) { }

  ngOnInit(): void {
    // Bug fix for capitalization in sorting
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
    this.data$ = this.store.pipe(select(optionSelector.allOptions)).pipe(tap(options => {
      this.dataSource.data = options.specialFindings;
      // eslint-disable-next-line arrow-body-style
      this.dataSource.filterPredicate = (data, filter) => {
        // eslint-disable-next-line arrow-body-style
        return this.headElements.some(ele => {
          return ele !== 'actions' && ele !== 'isActive' && ele !== 'category' && data[ele].toLowerCase().indexOf(filter) !== -1;
        });
      };
    }));
  }

  openModal(option: SubCategoryModel): void {
    const dialogRef = this.dialog.open(AddEditFindingsComponent, {
      width: '500px',
      data: { ...option }
    });

    // New Special Finding
    if (option?.id == null) {
      this.subs$.push(
        dialogRef.afterClosed().subscribe((model: SubCategoryModel) => {
          if (model != null) {
            this.store.dispatch(optionsCreateSpecialFinding({ data: { option: { ...model } } }));
          }
        })
      );
    }

    // Update Special Finding
    if (option.id > 0) {
      this.subs$.push(
        dialogRef.afterClosed().subscribe((model: SubCategoryModel) => {
          if (model != null) {
            this.store.dispatch(optionsUpdateSpecialFinding({ data: { option: { ...model } } }));
          }
        })
      );
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  applyFilter() {
    if (this.searchKey != null) {
      this.dataSource.filter = this.searchKey.trim().toLowerCase();
    }
  }


  sortData(sort: Sort): void {
    this.sort.direction = sort.direction;
    this.sort.active = sort.active;
    this.data$ = this.store.pipe(select(optionSelector.allOptions)).pipe(tap(options => this.dataSource.data = options.specialFindings));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

}
