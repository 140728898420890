import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthModule, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { ConfigurationService } from '../configuration/configuration.service';
import { AuthenticationEffects } from './state/authentication.effects';
import * as fromAuthentication from './state/authentication.reducer';

const authFactory = (configService: ConfigurationService) => {
  const config = configService.getConfig();
  return new StsConfigStaticLoader(config);
};


@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [ConfigurationService],
      },
    }),
    StoreModule.forFeature(fromAuthentication.AUTHENTICATION_FEATURE_KEY, fromAuthentication.reducer),
    EffectsModule.forFeature([AuthenticationEffects]),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }
