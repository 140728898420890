import { Injectable } from '@angular/core';
import { ClaimsModel } from '@core/services/auth/models/claims.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { LdapUser } from '../models/ldap-user.model';
import { usersAddRole, usersRemoveRole } from '../store/user.actions';
import { UserState } from '../store/user.reducers';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {

  constructor(private store: Store<UserState>, private userService: UserService) { }

  addUserRole(userName: string, role: 'Admin' | 'Reader'): void {
    const userClaim: ClaimsModel = { type: `role_${role.toLowerCase()}`, value: role };
    const payload = { data: { user: userName, claim: userClaim } };
    return this.store.dispatch(usersAddRole(payload));
  }

  removeUserRole(userName: string, role: 'Admin' | 'Reader'): void {
    const userClaim: ClaimsModel = { type: `role_${role.toLowerCase()}`, value: role };
    const payload = { data: { user: userName, claim: userClaim } };
    return this.store.dispatch(usersRemoveRole(payload));
  }

  userSearch(search: string): Observable<LdapUser[]> {
    return this.userService.inputSearch(search);
  }
}
