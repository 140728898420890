import { Action, createReducer, on } from '@ngrx/store';
import { ApplicationUser, IdentityUser } from '../models/application-user.model';
import { login, logout } from './authentication.actions';

export const AUTHENTICATION_FEATURE_KEY = 'authentication';

export interface AuthState {
  userData: IdentityUser;
  accessToken: string;
  isWarden: boolean;
  isAdmin: boolean;
}

export const initialAuthState: AuthState = {
  userData: {} as IdentityUser,
  accessToken: '',
  isWarden: false,
  isAdmin: false,
};

export const authReducer = createReducer(
  initialAuthState,

  on(login, (state, action) => ({
      userData: action.userData,
      accessToken: action.accessToken,
      isWarden: action.isWarden,
      isAdmin: action.isAdmin,
    })),

  on(logout, () => initialAuthState)
);

export const reducer = (state: AuthState | undefined, action: Action): AuthState => authReducer(state, action);
