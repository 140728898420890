<mat-card class="animate__animated animate__fadeIn">
  <app-card-header title="Reports" subtitle="IRB Reports"></app-card-header>
  <mat-card-content class="p-3">
    <div class="search-div">
      <form [formGroup]="reportService.form" novalidate (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by IRB Number</mat-label>
              <input formControlName="irbNumber" name="irbNumber" placeholder="IRB Number" matInput>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by IRBNet Number</mat-label>
              <input formControlName="irbNetNumber" name="irbNetNumber" placeholder="IRBNet Number" matInput>
            </mat-form-field>

          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Investigator</mat-label>
              <input type="text" matInput formControlName="investigatorId" [matAutocomplete]="investigatorAutoComplete">
              <mat-autocomplete #investigatorAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let investigator of filteredInvestigators$ | async" [value]="investigator">
                  {{investigator.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by NTRIBCoordinator</mat-label>
              <input type="text" matInput formControlName="ntrIrbCoordinatorId" [matAutocomplete]="ntrIrbCoordinatorAutoComplete">
              <mat-autocomplete #ntrIrbCoordinatorAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let ntrIrbCoordinator of filteredNtrIrbCoordinators$ | async" [value]="ntrIrbCoordinator">
                  {{ntrIrbCoordinator.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Institution</mat-label>
              <input type="text" matInput formControlName="institutionId" [matAutocomplete]="institutionAutoComplete">
              <mat-autocomplete #institutionAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let institution of filteredInstitutions$ | async" [value]="institution">
                  {{institution.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by School</mat-label>
              <input type="text" matInput formControlName="schoolId" [matAutocomplete]="schoolAutoComplete">
              <mat-autocomplete #schoolAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let school of filteredSchools$ | async" [value]="school">
                  {{school.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Department</mat-label>
              <input type="text" matInput formControlName="departmentId" [matAutocomplete]="departmentAutoComplete">
              <mat-autocomplete #departmentAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredDepartments$ | async" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Status</mat-label>
              <input type="text" matInput formControlName="statusId" [matAutocomplete]="statusAutoComplete">
              <mat-autocomplete #statusAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let status of filteredStatus$ | async" [value]="status">
                  {{status.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Type Of Review</mat-label>
              <input type="text" matInput formControlName="typeOfReviewId" [matAutocomplete]="typeOfReviewAutoComplete">
              <mat-autocomplete #typeOfReviewAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let typeOfReview of filteredTypeOfReview$ | async" [value]="typeOfReview">
                  {{typeOfReview.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Choose Date Range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input formControlName="startDate" matStartDate placeholder="Start date"
                  (dateChange)="checkValue($event)">
                <input formControlName="endDate" matEndDate placeholder="End date" (dateChange)="checkValue($event)">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Choose a date range before making selection</mat-label>
              <mat-select formControlName="dates" multiple>
                <mat-option *ngFor="let dateOption of dateMultiSelect" [value]="dateOption.value">{{dateOption.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Study Title or Special Finding Category</mat-label>
              <input formControlName="keyword" name="keyword" placeholder="" matInput>
            </mat-form-field>
          </div>
          </div>
          <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Search by Type Of Research</mat-label>
              <input type="text" matInput formControlName="typeOfResearchId" [matAutocomplete]="typeOfResearchAutoComplete">
              <mat-autocomplete #typeOfResearchAutoComplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredTypeOfResearch$ | async" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="mr-3" mat-stroked-button color="warn" (click)="resetFilter()">Clear Search</button>
          <button class="mr-3" mat-stroked-button color="primary" type="submit">Submit</button>
          <div class="button-container">
            <span class="spinner-container" *ngIf="downloadingStatusReport">
              <mat-spinner diameter="24"></mat-spinner>
            </span>
            <button class="mr-3" mat-stroked-button color="primary" mat-raised-button
              [disabled]="downloadingStatusReport" (click)="downloadExcel(false)">
              <mat-icon>download</mat-icon>Status Report
            </button>
          </div>
          <div class="button-container">
            <span class="spinner-container" *ngIf="downloadingFullReport">
              <mat-spinner diameter="24"></mat-spinner>
            </span>
            <button class="mr-3" mat-stroked-button color="primary" mat-raised-button [disabled]="downloadingFullReport"
              (click)="downloadExcel(true)">
              <mat-icon>download</mat-icon>Full Report
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-paginator #topPaginator [length]="dataSource.totalCount | async" [pageSize]="reportService.pageSize" [pageSizeOptions]="[10, 50, 100]" [pageIndex]="reportService.pageIndex" (page)="onPageChange($event)"></mat-paginator>
  <div class="table-container" [hidden]="(dataSource.loading$ | async) || (dataSource.totalCount | async) < 0">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData()" matSortActive="institutionId" matSortDirection="asc"
      class="mat-elevation-z8">
      <ng-container matColumnDef="irbNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IRB Number </th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/protocol/', element.protocolId]" (click)="view(element.protocolId)">
            {{ element.boardReferenceNumber }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="institutionId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Institution </th>
        <td mat-cell *matCellDef="let element"> {{ element.institution }} </td>
      </ng-container>
      <ng-container matColumnDef="schoolId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>School </th>
        <td mat-cell *matCellDef="let element"> {{ element.school }} </td>
      </ng-container>
      <ng-container matColumnDef="departmentId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department </th>
        <td mat-cell *matCellDef="let element"> {{ element.department }} </td>
      </ng-container>
      <ng-container matColumnDef="investigatorId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Investigator </th>
        <td mat-cell *matCellDef="let element"> {{ element.investigator }} </td>
      </ng-container>
      <ng-container matColumnDef="ntrIrbCoordinatorId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NTRIRB Coordinator</th>
        <td mat-cell *matCellDef="let element"> {{ element.ntrIrbCoordinator }} </td>
      </ng-container>
      <ng-container matColumnDef="statusId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
        <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
      </ng-container>
      <ng-container matColumnDef="typeOfReviewId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type of Review </th>
        <td mat-cell *matCellDef="let element"> {{ element.typeOfReview }} </td>
      </ng-container>
      <ng-container matColumnDef="approvalThrough">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Approval Through</th>
        <td mat-cell *matCellDef="let element"> {{ element.approvalThrough | date}} </td>
      </ng-container>
      <ng-container matColumnDef="sendNextReviewDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Send Next Review</th>
        <td mat-cell *matCellDef="let element"> {{ element.sendNextReviewDate | date }} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="!(dataSource.loading$ | async) && (dataSource.totalCount | async) == 0"
      style="color: #CCD1D1; text-align: center;">
      <span>No data found</span>
    </div>
    <mat-paginator [length]="dataSource.totalCount | async" [pageSize]="reportService.pageSize" [pageSizeOptions]="[10, 50, 100]" [pageIndex]="reportService.pageIndex" (page)="onPageChange($event)"></mat-paginator>
  </div>
  <div *ngIf="dataSource.loading$ | async" style="position: absolute; right: 50%; top: 35%;">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</mat-card>
