import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { scrollFabAnimation } from '@shared/services/animations/scroll-fab.animation';
import { WINDOW } from '@shared/services/window.token';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll-core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, share, tap, throttleTime } from 'rxjs/operators';

enum ShowStatus {
  show = 'show',
  hide = 'hide',
}

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  animations: [scrollFabAnimation],
})
export class ScrollToTopComponent implements AfterViewInit, OnDestroy {
  private stateSubject = new BehaviorSubject<string>(ShowStatus.hide);
  state$ = this.stateSubject.asObservable();

  pageScrollInstance: PageScrollInstance;

  constructor(
    @Inject(WINDOW) private window: Window,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.pageScrollInstance = {} as PageScrollInstance;
  }

  scrollToTop(): void {
    this.pageScrollService.start(this.pageScrollInstance);
  }

  ngAfterViewInit(): void {
    this.pageScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollTarget: '#top',
    });

    const scroll$ = fromEvent(this.window, 'scroll').pipe(
      throttleTime(10),
      map(() => this.window.pageYOffset),
      map((y) => {
        if (y > 100) {
          return ShowStatus.show;
        } else {
          return ShowStatus.hide;
        }
      }),
      distinctUntilChanged(),
      share(),
      tap((state) => this.stateSubject.next(state))
    );
    scroll$.subscribe();
  }

  ngOnDestroy(): void {}
}
