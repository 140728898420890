import { Injectable } from '@angular/core';
import { ApplicationUser } from '@core/services/auth/models/application-user.model';
import { ErrorHandlerService } from '@core/services/error-handler/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxNotifyService } from '@shared/services/ngx-notify/ngx-notify.service';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import * as userActions from './user.actions';

@Injectable()
export class UserEffects {

    usersLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.usersLoad),
            mergeMap(() =>
                this.userService.getAllUsers().pipe(
                    map((applicationUsers: ApplicationUser[]) => userActions.usersLoaded({ users: this.populateUsers(applicationUsers) })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    usersAddRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.usersAddRole),
            switchMap((action) =>
                this.userService.addUserRole(action.data.user, action.data.claim).pipe(
                    map(() => {
                        this.notify.success('User role added');
                        return userActions.usersLoad();
                    }),
                    catchError((err) => {
                        this.notify.error(this.errorHandler.handleError(err));
                        return EMPTY;
                    })
                )
            )
        )
    );
    usersRemoveRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.usersRemoveRole),
            switchMap((action) =>
                this.userService.deleteUserRole(action.data.user, action.data.claim).pipe(
                    map(() => {
                        this.notify.success('User role removed');
                        return userActions.usersLoad();
                    }),
                    catchError((err) => {
                        this.notify.error(this.errorHandler.handleError(err));
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(
        private userService: UserService,
        private actions$: Actions,
        private notify: NgxNotifyService,
        private errorHandler: ErrorHandlerService
        ) { }

    private isAdmin = (user: ApplicationUser): boolean =>
        user?.claims?.length ? user.claims.filter(x => x.type.includes('_admin')).length > 0 : false;

    private isWarden = (user: ApplicationUser): boolean =>
        user?.claims?.length ? user.claims.filter(x => x.type.includes('_warden')).length > 0 : false;

    private populateUsers(users: ApplicationUser[]): User[] {
        return users.map(user => ({
            userData: user,
            isWarden: this.isWarden(user),
            isAdmin: this.isAdmin(user),
            claims: user.claims ?? []
        }));
    }
}
