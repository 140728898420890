import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-rich-text-editor',
  template: `
    <quill-editor
      [formControl]="formControl"
      [formlyAttributes]="field"
      [modules]="{toolbar: false}"
      placeholder="Special Findings Notes"
      >
    </quill-editor>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextEditorComponent extends FieldType {}
