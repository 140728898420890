<div style="min-width: 500px;" #dialogRef>
    <div class="modal-header">
        <span mat-dialog-title>Add User</span>
    </div>

    <div class="modal-body">
        <mat-dialog-content class="mat-typography" style="min-height: 250px;">
            <div class="row mt-3">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Add User</mat-label>
                    <input #typehead type="text" placeholder="Name/EUID" aria-label="search" matInput
                        [formControl]="userSearch" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of searchResults" [value]="option.samAccountName">
                            {{ option.samAccountName }} - {{ option.firstName }} {{ option.lastName }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="row mt-3">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Add Role</mat-label>
                    <mat-select matNativeControl [formControl]="userRole">
                        <ng-container>
                            <mat-option value="Admin">Admin</mat-option>
                            <mat-option value="Reader">Reader</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-dialog-content>
    </div>

    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close color="warn">Close</button>
        <button mat-stroked-button color="accent" [disabled]="!userRole.valid || !userSearch.valid" (click)="addUserRole()">Submit</button>
    </mat-dialog-actions>
</div>