import { Injectable } from '@angular/core';
import { ApplicationUser } from '@core/services/auth/models/application-user.model';
import { ClaimsModel } from '@core/services/auth/models/claims.model';
import { ApiService } from '@shared/services/api/api.service';
import { Observable } from 'rxjs';
import { LdapUser } from '../models/ldap-user.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    private endpoint = 'user';

    constructor(private apiService: ApiService) {}

    getAllUsers(): Observable<ApplicationUser[]> {
        return this.apiService.get<ApplicationUser[]>(`${this.endpoint}/getuserswithroles`);
    }

    addUserRole(euid: string, claim: ClaimsModel): Observable<ApplicationUser | ClaimsModel> {
        return this.apiService.create<ApplicationUser | ClaimsModel>(`${this.endpoint}/${euid}/addclaim`, claim);
    }

    deleteUserRole(euid: string, claim: ClaimsModel): Observable<ApplicationUser | ClaimsModel> {
        return this.apiService.create<ApplicationUser | ClaimsModel>(`${this.endpoint}/${euid}/removeclaim`, claim);
    }

    inputSearch(input: string): Observable<LdapUser[]> {
        return this.apiService.get<LdapUser[]>(`${this.endpoint}/TokenInputSearch/${input}`);
    }
}
