import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { authSelector } from '@core/services/auth/state/authentication.selectors';
import { select, Store } from '@ngrx/store';
import { SearchFilter, SearchModel } from '@shared/models/search.model';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@shared/services/animations/route.animations';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ProtocolModel } from '../models/protocol.model';
import { protocolSelector } from '../store/protocol.selectors';
import { AppState } from './../../../core/app-state/reducers';
import { protocolById, protocolSearch } from './../store/protocol.actions';
import { OptionModel } from '@features/admin/option-management/models/option.model';
import { optionSelector } from '@features/admin/option-management/store/option.selectors';
import { OptionType } from '@features/admin/option-management/models/option-type.enum';
import { ProtocolService } from '../services/protocol.service';

@Component({
  selector: 'app-protocols-list',
  templateUrl: './protocols-list.component.html',
  styleUrls: ['./protocols-list.component.scss'],
})
export class ProtocolsListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  headElements = ['irbNumber', 'investigator', 'studyTitle', 'status', 'approvalThrough', 'actions'];
  resultsLength = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 50, 100];

  data$!: Observable<ProtocolModel[]>;
  loadingProtocols$ = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingProtocols$.asObservable();
  subs$: Subscription[] = [];
  isAdmin$ = this.store.pipe(select(authSelector.isAdmin));

  //filter = '';
  status!: OptionModel[];
  filteredStatus$!: Observable<OptionModel[]>;
  investigators!: OptionModel[];
  filteredInvestigators$!: Observable<OptionModel[]>;
  typeOfReview!: OptionModel[];
  filteredTypeOfReview$!: Observable<OptionModel[]>;
  ntrIrbCoordinators!: OptionModel[];
  filteredNtrIrbCoordinators$!: Observable<OptionModel[]>;

  constructor(
    private store: Store<AppState>,
    public protocolService: ProtocolService,
    private router: Router) { }

  ngOnInit(): void {
    this.setFormOptions();
    this.data$ = this.store.pipe(select(authSelector.accessToken)).pipe(switchMap(token => {
      if (token != null && token !== '') {
        this.insert(this.protocolService.protocolFilterForm.value);
        const filters = new SearchModel();
        filters.order = this.sort?.direction || 'desc';
        filters.sort = this.sort?.active || 'irbNumber';
        filters.pageSize = this.protocolService?.pageSize;
        filters.pageIndex = this.protocolService?.pageIndex;
        filters.filter = { ...this.protocolService.searchFilterModel } as SearchFilter;     
        return this.getProtocols(filters);
      }
      return of([]);
    }));
  }

  onSubmit(): void {
    this.insert(this.protocolService.protocolFilterForm.value);
    this.protocolService.pageIndex = 0
    this.loadData();
  }

  loadData(){
    const filters = new SearchModel();
    filters.order = this.sort?.direction || 'desc';
    filters.sort = this.sort?.active || 'irbNumber';  
    filters.pageSize = this.protocolService?.pageSize
    filters.pageIndex = this.protocolService?.pageIndex; 
    filters.filter = { ...this.protocolService.searchFilterModel } as SearchFilter;
    return this.getProtocols(filters);
  }

  view(protocolId: number): void {
    this.store.dispatch(protocolById({ id: protocolId }));
    this.router.navigate(['/protocol', protocolId]);
  }

  sortData(): void {
    this.protocolService.pageIndex = this.paginator.pageIndex = 0;//reset index to 0 
    this.loadData();
  }

  onPageChange(event: any) {
    this.protocolService.pageSize  = event.pageSize;
    this.protocolService.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize; // Update the central pageSize
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

  private getProtocols(filters: SearchModel): Observable<ProtocolModel[]> {
    this.loadingProtocols$.next(true);
    this.store.dispatch(protocolSearch({ options: filters }));
    return this.store.pipe(select(protocolSelector.allProtocols)).pipe(map(x => {
      this.resultsLength = x?.count;
      this.loadingProtocols$.next(false);
      return x?.protocols;
    }));
  }


  resetFilter(): void {
    this.protocolService.protocolFilterForm.reset();
    this.insert(this.protocolService.protocolFilterForm.value);
    this.paginator.pageIndex = 0;  // If the user applies filter, reset back to the first page.
    this.sortData();

  }

  private insert(data: any): void {
    if (data != null) {
      if (data.studyTitle != null) {
        this.protocolService.searchFilterModel.studyTitle = data.studyTitle;
      }
      else {
        this.protocolService.searchFilterModel.studyTitle = '';
      }
      if (data.ntrIrbCoordinatorId != null && data.ntrIrbCoordinatorId !== undefined && data.ntrIrbCoordinatorId !== 0 && data.ntrIrbCoordinatorId !== '') {
        this.protocolService.searchFilterModel.ntrIrbCoordinatorId = data.ntrIrbCoordinatorId.id;
      }
      else {
        this.protocolService.searchFilterModel.ntrIrbCoordinatorId = undefined;
      }

      if (data.investigatorId != null && data.investigatorId !== undefined && data.investigatorId !== 0 && data.investigatorId !== '') {
        this.protocolService.searchFilterModel.investigatorId = data.investigatorId.id;
      }
      else {
        this.protocolService.searchFilterModel.investigatorId = undefined;
      }


      if (data.statusId != null && data.statusId !== undefined && data.statusId !== 0 && data.statusId !== '') {

        this.protocolService.searchFilterModel.statusId = data.statusId.id;
      }
      else {
        this.protocolService.searchFilterModel.statusId = undefined;
      }

      if (data.approvalThrough != null) {
        this.protocolService.searchFilterModel.approvalThrough = data.approvalThrough.toLocaleString();
      }
      else {
        this.protocolService.searchFilterModel.approvalThrough = '';
      }

      if (data.irbNumber != null) {
        this.protocolService.searchFilterModel.irbNumber = data.irbNumber;
      }
      else {
        this.protocolService.searchFilterModel.irbNumber = '';
      }
      if (data.irbNetNumber != null) {
        this.protocolService.searchFilterModel.irbNetNumber = data.irbNetNumber;
      }
      else {
        this.protocolService.searchFilterModel.irbNetNumber = '';
      }
      if (data.sendNextReview != null) {
        this.protocolService.searchFilterModel.sendNextReview = data.sendNextReview.toLocaleString();
      }
      else {
        this.protocolService.searchFilterModel.sendNextReview = '';
      }
    }
  }

  private setFormOptions(): void {
    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.investigator))).subscribe((investigators: OptionModel[]) => {
      this.investigators = investigators;
      this.filteredInvestigators$ = this.protocolService.protocolFilterForm.controls.investigatorId?.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.investigators) : this.investigators.slice()));
    }));

    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.nTRIRBCoordinator))).subscribe((options: OptionModel[]) => {
      this.ntrIrbCoordinators = options;
      this.filteredNtrIrbCoordinators$ = this.protocolService.protocolFilterForm.controls.ntrIrbCoordinatorId?.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.ntrIrbCoordinators) : this.ntrIrbCoordinators.slice()));
    }));


    this.subs$.push(this.store.pipe(select(optionSelector.optionsByType(OptionType.status))).subscribe((options: OptionModel[]) => {
      this.status = options;
      this.filteredStatus$ = this.protocolService.protocolFilterForm.controls.statusId?.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this._filter(name, this.status) : this.status.slice()));
    }));
  }
  private _filter(name: string, optionList: OptionModel[]): any {
    const filterValue = name.trim().toLowerCase();
    return optionList.filter(option => option.name.trim().toLowerCase().includes(filterValue));
  }

  displayFn(option: OptionModel): string {
    return option && option.name ? option.name : '';
  }

  onDateChange(dateString: any, formControl: string) {
    if (dateString != null) {
      this.protocolService.protocolFilterForm.controls[formControl].setValue(new Date(dateString.target.value.substring(0, 2) + "/" + dateString.target.value.substring(2, 4) + "/" +
        dateString.target.value.substring(4, dateString.length)));
    }
  }
}
