import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

// TODO:
// Extend this to notify user of useful error messages

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const responseStatusCode: number = event.status;
          this.statusCodeHandler(responseStatusCode);
        }
      }),
      catchError(
        (error: Error | HttpErrorResponse): Observable<any> => {
          if (error instanceof HttpErrorResponse) {
            const errorStatusCode: number = error.status;
            this.statusCodeHandler(errorStatusCode);
          }
          return throwError(error);
        }
      )
    );
  }

  private statusCodeHandler(code: number): void {
    switch (code) {
      case 401:
        this.router.navigate(['/autologin']);
        break;
    }
  }
}
