import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from './user.effects';
import { userReducer, USER_FEATURE_KEY } from './user.reducers';

@NgModule({
    imports: [
        StoreModule.forFeature(USER_FEATURE_KEY, userReducer),
        EffectsModule.forFeature([UserEffects])
    ]
})

export class UserStoreModule { }
