import { Action, createReducer, on } from '@ngrx/store';
import { CategoryModel } from '../models/category.model';
import { OptionModel } from '../models/option.model';
import { SubCategoryModel } from '../models/sub-category.model';
import { optionsLoaded } from './option.actions';

export const OPTION_FEATURE_KEY = 'options';

export interface OptionState {
  [key: string]: any;
  options: OptionModel[];
  specialFindings: SubCategoryModel[];
  categories: CategoryModel[];
}

export const initialOptionState: OptionState = {
  options: [],
  specialFindings: [],
  categories: []
};

export const optionReducer = createReducer(
  initialOptionState,

  on(optionsLoaded, (state, { options, specialFindings, categories }) => ({ ...state, options, specialFindings, categories }))
);

export const reducer = (state: OptionState, action: Action) => optionReducer(state, action);
