import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ReportService {
    pageSize = 10;
    pageIndex = 0;
    filter = '';
    form :FormGroup = this.fb.group({
      investigatorId: [0],
      institutionId: [0],
      schoolId: [0],
      departmentId: [0],
      statusId: [0],
      irbNumber: [null],
      startDate: [null],
      endDate: [null],
      typeOfReviewId: [0],
      ntrIrbCoordinatorId: [0],
      irbNetNumber: [null],
      dates: [{ value: null, disabled: true }],
      keyword: [null],
      typeOfResearchId: [0]
    });

    constructor(private fb: FormBuilder,) { 

    }

}
