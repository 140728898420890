import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CategoryModel } from '../../models/category.model';
import { OptionTypeService } from '../../services/option-type.service';

@Component({
  selector: 'app-add-edit-findings-category',
  templateUrl: './add-edit-findings-category.component.html',
  styleUrls: ['./add-edit-findings-category.component.scss']
})
export class AddEditFindingsCategoryComponent implements OnInit {
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CategoryModel,
    private dialogRef: MatDialogRef<AddEditFindingsCategoryComponent>,
    private service: OptionTypeService) { }

  ngOnInit(): void {
    this.fields = this.service.buildCategoryForm({ ...this.data });
  }

  save(): void {
    this.dialogRef.close(this.data);
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
