import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { login, logout } from './authentication.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationEffects {
  login$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(login),
        tap((action) => localStorage.setItem('userData', JSON.stringify(action.userData)))
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(logout),
        tap(() => localStorage.removeItem('userData'))
      ),
    { dispatch: false }
  );

  constructor(private action$: Actions) {}
}
