import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OptionEffects } from './option.effects';
import { optionReducer, OPTION_FEATURE_KEY } from './option.reducers';

@NgModule({
    imports: [
        StoreModule.forFeature(OPTION_FEATURE_KEY, optionReducer),
        EffectsModule.forFeature([OptionEffects])
    ]
})

export class OptionStoreModule { }
