<mat-card style="min-height: 700px">
    <app-card-header title="Option Management" subtitle="IRB Options" [ngClass]="routeAnimationsElements"></app-card-header>
    <mat-card-content class="p-3">
        <mat-tab-group [selectedIndex]="selected$ | async" (selectedIndexChange)="tabChange($event)"
            [ngClass]="routeAnimationsElements">
            <ng-container *ngFor="let type of optionTypeArray">
                <mat-tab [label]="type | optionType">
                        <div class="search-div">
                            <mat-form-field class="search-form-field" floatLabel="never">
                              <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off"
                                (keyup)="applyFilter()">
                              <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey"
                              (click)="onSearchClear()">
                              <mat-icon>close</mat-icon>
                            </button>
                            </mat-form-field>
                            <button mat-stroked-button color="accent" class="mb-3" (click)="openCreateModal(type)"><mat-icon>add</mat-icon>Add {{ type | optionType }}</button>
                          </div>
                    <div class="table-container mt-3">
                        <table mat-table *ngIf="data$ | async as data" [dataSource]="dataSource" matSort
                            (matSortChange)="sortData($event)" [ngClass]="routeAnimationsElements">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header [disableClear]="true">Title</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.name }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="isActive">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.isActive ? 'Active' : 'Inactive' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container>
                                        <button class="mr-2" mat-stroked-button color="accent"
                                            (click)="openEditModal(element)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="headElements"></tr>
                            <tr mat-row *matRowDef="let row; columns: headElements;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 20, 30]" [length]="resultsLength"
                            (page)="pageData($event)"></mat-paginator>
                    </div>
                </mat-tab>
            </ng-container>

            <!-- Special Findings -->
            <mat-tab label="Special Findings Category">
                <app-findings-category *ngIf="(selected$ | async) === 10"></app-findings-category>
            </mat-tab>

            <!-- Special Findings -->
            <mat-tab label="Special Findings">
                <app-findings *ngIf="(selected$ | async) === 11"></app-findings>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>