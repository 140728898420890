import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { OptionModel } from '../../models/option.model';
import { OptionTypeService } from '../../services/option-type.service';

@Component({
  selector: 'app-add-edit-base-option',
  templateUrl: './add-edit-base-option.component.html',
  styleUrls: ['./add-edit-base-option.component.scss']
})
export class AddEditBaseOptionComponent implements OnInit {
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OptionModel,
    private dialogRef: MatDialogRef<AddEditBaseOptionComponent>,
    private service: OptionTypeService) { }

  ngOnInit(): void {
    this.fields = this.service.buildOptionForm(this.data.optionType, this.data);
  }

  save(): void {
    this.dialogRef.close(this.data);
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
