import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { NgxNotifyService } from '@shared/services/ngx-notify/ngx-notify.service';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { LdapUser } from '../../models/ldap-user.model';
import { User } from '../../models/user.model';
import { UserStoreService } from '../../services/user-store.service';
import { UserState } from '../../store/user.reducers';
import { userSelector } from '../../store/user.selectors';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit, OnDestroy {
  @ViewChild('typehead', { read: MatAutocompleteTrigger }) autoTrigger!: MatAutocompleteTrigger;
  data$!: Observable<User[]>;
  userSearch: FormControl = new FormControl('', Validators.required);
  userRole: FormControl = new FormControl('', Validators.required);
  searchResults: LdapUser[] = [];
  subs$: Subscription[] = [];

  constructor(
    private store: Store<UserState>,
    private userService: UserStoreService,
    private dialogRef: MatDialogRef<UserAddComponent>,
    private notify: NgxNotifyService) { }

  ngOnInit(): void {
    this.data$ = this.store.pipe(select(userSelector.allUsers));
    this.watchUserSearch();
  }

  addUserRole(): void {
    this.subs$.push(
      this.store.select(userSelector.userByUserId(this.userSearch.value)).subscribe(result => {
        if (result) {
          this.notify.warn('This user already exists in the system. Please select another user.');
        } else {
          this.userService.addUserRole(this.userSearch.value, this.userRole.value);
          this.dialogRef.close();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub.unsubscribe());
  }

  private watchUserSearch(): void {
    this.subs$.push(
      this.userSearch.valueChanges.pipe(
        switchMap(value => {
          if (value.length > 0) {
            return this.userService.userSearch(value).pipe(tap(results => this.searchResults = results));
          }

          this.searchResults = [];
          return [];
        }

        )
      ).subscribe()
    );
  }
}
