import { Injectable } from '@angular/core';
import { ErrorResultModel } from '@shared/models/error-result.model';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
    constructor() { }

    handleError<T>(error: any): string {
        if (error.status === 401) {
            return 'Unauthorized';
        }

        if (error.status === 500) {
            return 'An error was detected. Please refresh your browser and try again.';
        }

        if (error.error?.errors != null) {
            return 'Please check your submission and try again';
        }

        const resultModel = { ...error.error } as ErrorResultModel<T>;
        return resultModel.extendedMessage;
    }
}
