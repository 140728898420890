<mat-card style="min-height: 700px">
    <app-card-header title="User Management" subtitle="IRB Users" [ngClass]="routeAnimationsElements"></app-card-header>
    <mat-card-content class="p-3">
        <div fxLayout="row" fxLayoutAlign="end">
            <button mat-stroked-button color="accent" class="mb-3" (click)="add()" [ngClass]="routeAnimationsElements"><mat-icon>add</mat-icon>Add User</button>
        </div>
        <div class="table-container" *ngIf="data$ | async as data" [ngClass]="routeAnimationsElements">
            <mat-table [dataSource]="dataSource" [class.isMobile]="isMobile">
                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="mobile-label">First Name:</span>
                        {{ element.userData.firstName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="mobile-label">Last Name:</span>
                        {{ element.userData.lastName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="roles">
                    <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="mobile-label">Role:</span>
                        {{ !element.isWarden ? element.claims[0].value : "Developer" }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="!element.isWarden">
                            <span class="mobile-label">Actions:</span>
                            <button class="mr-2" mat-stroked-button color="accent" (click)="edit(element)"><mat-icon>edit</mat-icon></button>
                            <button mat-stroked-button color="warn" (click)="remove(element)"><mat-icon>delete_outline</mat-icon></button>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="headElements"></mat-header-row>
                <mat-row *matRowDef="let row; columns: headElements;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>