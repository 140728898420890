import { Injectable } from '@angular/core';
import { AppState } from '@core/app-state/reducers';
import { OptionType } from '@features/admin/option-management/models/option-type.enum';
import { OptionModel } from '@features/admin/option-management/models/option.model';
import { optionSelector } from '@features/admin/option-management/store/option.selectors';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs/operators';

import { ProtocolModel } from '../models/protocol.model';
import { SearchFilter } from '@shared/models/search.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ProtocolService {
    model = {} as ProtocolModel;
    searchFilterModel: SearchFilter = {};
    protocolFilterForm: FormGroup = this.fb.group({
        investigatorId: [0],
        statusId: [0],
        irbNumber: [null],
        irbNetNumber: [null],
        approvalThrough: [null],
        sendNextReview: [null],
        typeOfReviewId: [0],
        ntrIrbCoordinatorId: [0],
        studyTitle: ['']
      });

      pageIndex = 0;
      pageSize = 10
    

    constructor(private store: Store<AppState>, private fb: FormBuilder,) { 

    }

    buildForm(model: ProtocolModel, isNew: boolean): FormlyFieldConfig[] {
        this.model = model;
        return [
            {
                template: '<div class="form-section mat-elevation-z4">Protocol Details</div>'
            },
            this.includeOldIrb(),
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'input',
                        key: 'studyTitle',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Study Title',
                            required: true
                        }
                    },
                ]
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'input',
                        key: 'irbNetNumber',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'IRBNet Number',
                            maxLength: 250
                        }
                    },
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'statusId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Status',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.status)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.statusId ?? 0, options))),
                        }
                    },
                ]
            },
            {
                template: '<div class="form-section mat-elevation-z4">Ownership</div>'
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'investigatorId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Investigator',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.investigator)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.investigatorId ?? 0, options))),
                        }
                    },
                    {
                        className: 'col-md-6 col-sm-12',
                        type: 'input',
                        key: 'coInvestigator',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Co-Investigator',
                            maxLength: 250
                        }
                    },
                ]
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'ntrIrbCoordinatorId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'NTRIRB Coordinator',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.nTRIRBCoordinator)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.ntrIrbCoordinatorId ?? 0, options))),
                        }
                    },
                    {
                        className: 'col-md-4 col-sm-12',
                        type: 'input',
                        key: 'crCoordinator',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'CR Coordinator',
                            maxLength: 250
                        }
                    },
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'typeOfResearchId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Type of Research',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.typeOfResearch)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.typeOfResearchId ?? 0, options))),
                        }
                    }
                ]
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-4 col-sm-12',
                        type: 'select',
                        key: 'departmentId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Department',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.department)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.departmentId ?? 0, options))),
                        }
                    },
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'schoolId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'School',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.school)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.schoolId ?? 0, options))),
                        }
                    },
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'institutionId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Institution',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.institution)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.institutionId ?? 0, options))),
                        }
                    },
                ]
            },
            {
                template: '<div class="form-section mat-elevation-z4">Sponsorship</div>'
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'sponsorTypeId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Sponsor Type',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.sponsorType)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.sponsorTypeId ?? 0, options))),
                        }
                    },
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'input',
                        key: 'sponsor',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Sponsor',
                            maxLength: 250
                        }
                    },
                    {
                        className: 'col-md-4 col-sm-12',
                        type: 'input',
                        key: 'sponsorProtocol',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Sponsor Protocol',
                            maxLength: 250
                        }
                    }
                ]
            },
            {
                template: '<div class="form-section mat-elevation-z4">Review</div>'
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'typeOfReviewId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Type of Review',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.typeOfReview)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.typeOfReviewId ?? 0, options))),
                        }
                    },
                    {
                        className: 'col-md-3 col-sm-12',
                        type: 'select',
                        key: 'reviewCycleId',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Review Cycle',
                            valueProp: 'id',
                            labelProp: 'name',
                            options: this.store.pipe(select(optionSelector.optionsByType(OptionType.reviewCycle)))
                                .pipe(map(options => this.appendSelectedOptionIfDisabled(model.reviewCycleId ?? 0, options))),
                        }
                    },
                ]
            },
            {
                template: '<div class="form-section mat-elevation-z4">Special Findings</div>'
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-sm-12',
                        type: 'select',
                        key: 'specialFindingCategories',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Special Findings Category',
                            multiple: true,
                            valueProp: 'id',
                            labelProp: 'name',
                            groupProp: 'category',
                            options: this.store.select(optionSelector.specialFindings).pipe(map(options => {
                                let activeOptions = options.filter(option => option.isActive);
                                const inactiveOptions = options.filter(option =>
                                        model.specialFindingCategories?.includes(option.id) && !option.isActive);

                                if (inactiveOptions.length > 0) {
                                    activeOptions = [...activeOptions, ...inactiveOptions];
                                }
                                activeOptions.sort((a, b) => a.name.localeCompare(b.name));
                                return activeOptions;
                            }))
                        }
                    },
                ]
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-12',
                        type: 'rich-text',
                        key: 'specialFindings',
                        templateOptions: {
                            appearance: 'fill',
                            placeholder: 'Special Findings Notes'
                        }
                    },
                ]
            },
            {
                template: '<div class="form-section mat-elevation-z4">Milestones</div>'
            },
            {
                template: '<div class="mt-3 mb-3"><span>All dates must occur after the initial submission date.</span></div>'
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'submissionDate',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Submission Date'
                        }
                    },
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'initialReview',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Initial Review Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'mostRecentReview',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Most Recent Review Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'initialApprovalDate',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Initial Approval Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                ]
            },
            {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'approvalThrough',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Approval Through Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'sendNextReview',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Send Next Review Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'continuingReviewDate',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Continuing Review Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                    {
                        className: 'col-md-3',
                        type: 'datepicker',
                        key: 'completeDate',
                        templateOptions: {
                            appearance: 'fill',
                            label: 'Complete Date'
                        },
                        expressionProperties: { 'templateOptions.datepickerOptions.min': 'model.submissionDate', }
                    },
                ]
            },
        ];
    }

    private appendSelectedOptionIfDisabled(id: number, options: OptionModel[]): OptionModel[] {
        const selectedOption = options.find(x => x.id === id);
        const activeOptions = options.filter(x => x.isActive === true);
        if (selectedOption != null && !selectedOption?.isActive) {
            activeOptions.push(selectedOption);
        }
        activeOptions.sort((a, b) => a.name.localeCompare(b.name));
        return activeOptions;
    }

    private includeOldIrb(): FormlyFieldConfig {
        if (this.model.previousIrbNumber != null) {
            return {
               template: `
                <div class="mb-2">
                    <span class="mat-h4">Previous IRB Number: </span><span>${this.model.previousIrbNumber}</span>
                </div>`
            };
        }

        return {};
    }



}
