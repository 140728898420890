import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'optionType' })
export class OptionTypePipe implements PipeTransform {
  transform(type: string): string {
    let responseType = type;
    switch (type) {
      case 'TypeOfResearch':
        responseType = 'Type of Research';
        break;
      case 'SponsorType':
        responseType = 'Sponsor Type';
        break;
      case 'ReviewCycle':
        responseType = 'Review Cycle';
        break;
      case 'TypeOfReview':
        responseType = 'Type of Review';
        break;
      case 'NTRIRBCoordinator':
        responseType = 'NTRIRB Coordinator';
        break;
    }

    return responseType;
  }
}
