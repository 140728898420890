import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { _window, WINDOW } from '@shared/services/window.token';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { CardHeaderComponent } from './components/card-header/card-header.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { MaterialModules } from './material/material.modules';

@NgModule({
  declarations: [
    ScrollToTopComponent,
    HeaderComponent,
    FooterComponent,
    CardHeaderComponent,
    ConfirmationDialogComponent,
    UnauthorizedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModules,
    FormsModule,
    ReactiveFormsModule,
    NgxPageScrollCoreModule
  ],
  exports: [
    MaterialModules,
    ScrollToTopComponent,
    HeaderComponent,
    FooterComponent,
    CardHeaderComponent,
    ConfirmationDialogComponent
  ],
  providers: [{ provide: WINDOW, useFactory: _window }],
})
export class SharedModule {}
